import React, {FC, ReactNode} from 'react'

import {
  SummaryLineItem,
  SummaryLineItemProps,
} from 'src/designSystem/components/molecules/SummaryCard/SummaryLineItem'
import GenericList from 'src/designSystem/components/molecules/GenericList/GenericList'

type SummaryCardProps = {
  lineItems: SummaryLineItemProps[]
}

const SummaryCard: FC<SummaryCardProps> = (props) => {
  const {lineItems} = props

  const getSummaryLineItems = (): ReactNode[] => {
    return lineItems.map((item, index) => (
      <SummaryLineItem label={item.label} value={item.value} key={index} />
    ))
  }

  return <GenericList items={getSummaryLineItems()} boxGap={'medium'} separatorMode="after-all" />
}

export {SummaryCard}
