import React, {FC, ReactElement} from 'react'

import {NamedColors} from 'src/designSystem/colors'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'

export type SummaryLineItemProps = {
  label: string | ReactElement
  value: string
}

const SummaryLineItem: FC<SummaryLineItemProps> = (props) => {
  const {label, value} = props

  return (
    <Box marginHorizontal={'medium'}>
      <PFText color={NamedColors.SILVER} variant={'label_sm'}>
        {label}
      </PFText>
      <PFText variant={'p'}>{value}</PFText>
    </Box>
  )
}

export {SummaryLineItem}
