import {StackNavigationProp} from '@react-navigation/stack'
import moment from 'moment'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {View} from 'react-native'

import {CardPaymentStatus} from 'src/cassandra'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'
import {SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {PFUnorderedList} from 'src/designSystem/components/molecules/PFUnorderedList/PFUnorderedList'
import {CardMinPaymentsQuery} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import {MinPayTableFields} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/MinPayTableFields'
import {MinPayTableHeader} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/MinPayTableHeader'
import {Color} from 'src/designSystem/types'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {MainStackNavigationProp} from 'src/nav/MainStackParamsList'
import {CardDashboardPaymentFooter} from 'src/products/card/Dashboard/CardDashboardPayment/CardDashboardPaymentFooter'
import {isNSF} from 'src/products/card/Dashboard/CardDashboardUtils'
import {useCardsType} from 'src/products/card/hooks/useCards'
import {CardAccountDashboardStatus} from 'src/products/card/types'

export type MinPayTablePropsFromUseCards = Pick<
  useCardsType,
  | 'nextPaymentDueDateFiltered'
  | 'autopayEnabled'
  | 'spentAmount'
  | 'eligibleForInstallments'
  | 'ledgerSpentAfterPayments'
  | 'cardAccount'
>

type MinPayTableProps = MinPayTablePropsFromUseCards & {
  setShowDetailsOverlay: React.Dispatch<React.SetStateAction<boolean>>
  cardMinPayResponse: CardMinPaymentsQuery['me']['cardAccounts']['active']
  autopayStatus: string
  navigation: StackNavigationProp<MainStackNavigationProp>
  accountStatus: CardAccountDashboardStatus | undefined
}

// eslint-disable-next-line complexity
export const MinPayTable: React.FC<MinPayTableProps> = ({
  setShowDetailsOverlay,
  cardMinPayResponse,
  autopayStatus,
  autopayEnabled,
  nextPaymentDueDateFiltered,
  accountStatus,
  ledgerSpentAfterPayments,
  cardAccount,
  navigation,
  spentAmount,
  eligibleForInstallments,
}) => {
  const {t} = useTranslation(['UpcomingPaymentsCard', 'Common'])

  let delinquentNumberOfDays: number | null | undefined
  if (cardMinPayResponse?.status.__typename === 'ActiveCardAccountStatus') {
    delinquentNumberOfDays = cardMinPayResponse?.status?.delinquentNumberOfDays
  }

  let headerTitle: string | undefined
  let pillContent: string | undefined
  let pillColor: Color | undefined
  let headerContent: string | React.ReactElement | undefined
  let headerIcon: SvgIconProps | undefined

  if (
    (accountStatus === CardAccountDashboardStatus.Delinquent ||
      accountStatus === CardAccountDashboardStatus.DelinquentNSF ||
      isNSF(cardMinPayResponse?.status)) &&
    cardMinPayResponse?.balance?.pastDueAfterPayments === '0.00' &&
    cardMinPayResponse?.payments?.pending?.find(
      (payment) => payment.status === CardPaymentStatus.InProgress,
    )
  ) {
    // Delinquent account with pending payment
    headerTitle = t('PaymentInProgress')
    headerContent = t('CardWillUnlockWhenProcessed')
    headerIcon = {
      name: 'clock',
      colorVariant: 'info',
      isFilled: true,
      size: 'medium',
    }
  } else if (
    accountStatus === CardAccountDashboardStatus.Delinquent ||
    accountStatus === CardAccountDashboardStatus.DelinquentNSF ||
    accountStatus === CardAccountDashboardStatus.DelinquencyReported ||
    accountStatus === CardAccountDashboardStatus.DelinquencyReportedNSF
  ) {
    // Delinquent

    pillContent = `$${cardMinPayResponse?.balance?.pastDueAfterPayments}`
    pillColor = 'error'
    headerTitle = t('PastDue')

    headerContent = (
      <View>
        <PFText variant="p">
          <Trans
            i18nKey="PastDueContent"
            t={t}
            values={{delinquentNumberOfDays}}
            components={{
              bold: <PFText variant="p_semibold" />,
              blue: (
                <PFText
                  onPress={(): void => navigation.navigate('CardHowDelinquencyAffectMe')}
                  variant="p"
                  color="link"
                />
              ),
            }}
          />
        </PFText>
        <PFUnorderedList
          items={[
            {value: t('PastDueBullet1'), key: '1'},
            {value: t('PastDueBullet2'), key: '2'},
            {value: t('PastDueBullet3'), key: '3'},
          ]}
        />
      </View>
    )
  } else if (isNSF(cardMinPayResponse?.status)) {
    // NSF

    pillContent = `$${cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments}`
    pillColor = 'error'
    headerTitle = t('AutoPayFailedTitle')
    headerContent = t('AutoPayFailed', {
      minimumDue: cardMinPayResponse?.balance?.minimumPaymentDueAfterPayments,
      statementDueDate: moment(cardMinPayResponse?.statements.mostRecent?.paymentDueDate).format(
        'MMM Do',
      ),
    })
  } else if (accountStatus === CardAccountDashboardStatus.DeactivatedDelinquent) {
    // Deactivated Delinquent

    pillContent = `$${cardMinPayResponse?.balance?.pastDueAfterPayments}`
    pillColor = 'error'
    headerTitle = t('PastDue')

    headerContent = (
      <View>
        <PFText variant="p">
          {t('MinPayDelinquentFooter', {
            minimumDue: cardMinPayResponse?.balance?.pastDueAfterPayments,
            statementDueDate: moment(
              cardMinPayResponse?.statements.mostRecent?.paymentDueDate,
            ).format('MMM Do'),
          })}
        </PFText>
        <SvgLink
          onPress={(): void => navigation.navigate('CardHowDelinquencyAffectMe')}
          linkText={t('LearnMore')}
          linkType="single"
          textVariant="p"
        />
        <Box marginTop="small">
          <PFText variant="p">{t('MinPayDeactivatedDelinquentFooter')}</PFText>
        </Box>
      </View>
    )
  } else if (accountStatus === CardAccountDashboardStatus.Deactivated && autopayEnabled) {
    // Deactivated, not delinquent, autopay enabled

    pillContent = `$${cardMinPayResponse?.payments?.schedule?.[0].currentExpectedAmount}`
    pillColor = 'info'
    headerTitle = t('AutopayScheduled')
    headerContent = t('MinPayDeactivatedFooter')
  } else if (accountStatus === CardAccountDashboardStatus.Deactivated && !autopayEnabled) {
    // Deactivated,  not delinquent, manual pay

    headerContent = t('MinPayDeactivatedManualPay')
  } else if (autopayEnabled) {
    // Current account with autopay enabled

    pillContent = `$${cardMinPayResponse?.payments?.schedule?.[0].currentExpectedAmount}`
    pillColor = 'info'
    headerTitle = t('AutopayScheduled')
  }

  return (
    <Box {...boxProps} testID="MinPay-Tile-Id" align={undefined}>
      <MinPayTableHeader
        pillColor={pillColor}
        pillContent={pillContent}
        headerTitle={headerTitle}
        headerContent={headerContent}
        headerIcon={headerIcon}
      />
      <Separator />
      <MinPayTableFields
        cardMinPayResponse={cardMinPayResponse}
        autopayStatus={autopayStatus}
        autopayEnabled={autopayEnabled}
        nextPaymentDueDateFiltered={nextPaymentDueDateFiltered}
      />
      <SvgLink
        onPress={(): void => {
          TrackAppEvent(CardEvents.card_key_info_details_clicked, AppEvents.Category.Card)
          setShowDetailsOverlay(true)
        }}
        linkText={t('ShowDetails')}
        linkType="inline"
        linkIcon="internal"
        testID="Show-Details-Link-Id"
      />
      <Separator />
      <CardDashboardPaymentFooter
        accountStatus={accountStatus}
        ledgerSpentAfterPayments={ledgerSpentAfterPayments}
        cardAccount={cardAccount}
        navigation={navigation}
        spentAmount={spentAmount}
        eligibleForInstallments={eligibleForInstallments}
      />
    </Box>
  )
}

const boxProps: BoxProps = {
  elevation: 4,
  radius: 'little',
  background: 'white',
  padding: 'small',
  gap: 'little',
  align: 'center',
  width: '100%',
}
