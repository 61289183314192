import React, {FC, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {Trans, useTranslation} from 'react-i18next'
import {ApolloError} from '@apollo/client'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {DefaultPaymentMethodCode} from '@possible/cassandra/src/types/types.mobile.generated'
import {AcceptAchDocument} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptACH/AcceptACH.gqls'
import {onACHAgreementLink} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptACH/AcceptACH.utils'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import AppEvents from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {
  updateDisbursementMethod,
  updatePaymentMethod,
} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAchAndAgreements.utils'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import NavPageState from 'src/navigation/NavPageState'
import {transferMethods, transferMethodsType} from 'src/lib/loans/consts'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {RadioButtonList} from 'src/designSystem/components/molecules/RadioButtonList/RadioButtonList'
import {usePfDispatch} from 'src/store/utils'
import {RadioOptionIdType} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAchAndAgreements.types'
import {ShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {ButtonLockupPropsPrimary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {NoAutoPayOverlay} from 'src/products/loans/LoanApprovedActivation/components/NoAutoPayOverlay/NoAutoPayOverlay'

export type AcceptACHProps = StackScreenProps<MainStackParamList, 'AcceptACH'>

const AcceptACH: FC<AcceptACHProps> = (props) => {
  const {navigation} = props
  const dispatch = usePfDispatch()

  const {t} = useTranslation(['LoanApproved', 'Common'])

  const [transferMethod, setTransferMethod] = useState<transferMethodsType | undefined>(undefined)
  const [selectedAutoPayOption, setSelectedAutoPayOption] = useState<string>('')
  const [isNoAutoPayOverlayVisible, setIsNoAutoPayOverlayVisible] = useState<boolean>(false)

  const stepName = LoanApprovedFlow.AcceptACH

  const {selectedData, error: queryError} = useCassandraQuery(
    AcceptAchDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (err: ApolloError): void => {
        Log.error(
          `${err.message} path=${err.graphQLErrors[0].path?.join(' ')}`,
          'AcceptACH query error',
        )
      },
    },
    (data) => {
      return {
        bankAccounts: data.me.bankAccounts,
        preferredAccount: data.me.bankAccounts.all?.filter(
          (account) => account.preferredFundingSource,
        )[0],
        loan: data.me.loans.latestActionableLoan,
        disbursementMethod: data.me.paymentMethods.loanEligible?.filter((paymentMethod) =>
          paymentMethod.defaultStatus.includes(DefaultPaymentMethodCode.DisbursementDefault),
        )[0],
      }
    },
  )

  const isQueryDataLoaded = !!selectedData?.bankAccounts
  const mask = selectedData?.preferredAccount?.mask ?? ''
  const loan = selectedData?.loan
  const loanId = selectedData?.loan?.id ?? null
  const disbursementDefault = selectedData?.disbursementMethod?.__typename

  let disbursementMethod: transferMethodsType = transferMethods.ach

  switch (disbursementDefault) {
    case 'AchPaymentMethod':
      disbursementMethod = transferMethods.ach
      break
    case 'CheckPaymentMethod':
      disbursementMethod = transferMethods.check
      break
    case 'DebitCardPaymentMethod':
      disbursementMethod = transferMethods.interchange
      break
    default:
      disbursementMethod = transferMethods.ach
  }

  const handleOnConfirmNoAutoPay = (): void => {
    TrackAppEvent(
      AppEvents.Name.accept_agreements_not_accept_ach_selected,
      AppEvents.Category.Checkout,
    )
    setTransferMethod(transferMethods.check)
    setSelectedAutoPayOption(noAutoPayId)
    setIsNoAutoPayOverlayVisible(false)
  }

  const onPressNoAutoPayRadioButton = (): void => {
    setSelectedAutoPayOption('')
    setIsNoAutoPayOverlayVisible(true)
  }
  const handleACHAgreementPressed = async (): Promise<void> => {
    try {
      await onACHAgreementLink(navigation, loanId, t)
    } catch (e) {
      Log.error(e)
      ShowException(e)
    }
  }

  const acceptAchId: RadioOptionIdType = 'accept-ach-radio-button'
  const noAutoPayId: RadioOptionIdType = 'no-auto-pay-radio-button'

  const paymentMethodText =
    transferMethod === transferMethods.interchange ? t('InstantDebit') : t('DirectDeposit')
  const noAutoPayText = t('DeclineAgreement', {paymentMethodText})
  const acceptAchText = (
    <PFText>
      <Trans i18nKey={'AchAuthorization'} values={{mask}} t={t}>
        I authorize Possible Finance to debit the account ending in {mask} on the dates listed on my
        Payment Schedule. This
        <SvgLink
          onPress={handleACHAgreementPressed}
          linkText={'Electronic Repayment Authorization'}
          linkType={'inline'}
        />
        is valid and will remain effective unless I cancel this authorization at least 3 business
        days in advance.
      </Trans>
    </PFText>
  )

  const options = [
    {
      id: acceptAchId,
      text: acceptAchText,
      testID: 'Accept-Ach-Agreement-Id',
    },
    {
      id: noAutoPayId,
      text: noAutoPayText,
      testID: 'Accept-Agreement-No-Auto-Pay-Id',
    },
  ]

  const handleRadioButtonPress = (option: string): void => {
    setSelectedAutoPayOption(option)
    if (option === acceptAchId) {
      TrackAppEvent(
        AppEvents.Name.accept_agreements_accept_ach_selected,
        AppEvents.Category.Checkout,
      )
      setTransferMethod(disbursementMethod)
    } else if (option === noAutoPayId && selectedAutoPayOption !== noAutoPayId) {
      onPressNoAutoPayRadioButton()
    }
  }

  const canContinue = (): boolean => {
    return selectedAutoPayOption !== ''
  }

  const onContinue = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.accept_agreements_completed, AppEvents.Category.Checkout)

    if (transferMethod === transferMethods.check) {
      await dispatch(updateDisbursementMethod(transferMethod, dispatch))
    }
    await dispatch(updatePaymentMethod(transferMethod, dispatch))

    const nextPage = NavPageState.GetNextApprovalState(stepName)
    await NavPageState.GoToScreen(navigation, nextPage)
  }

  const action: ButtonLockupPropsPrimary = {
    text: t('Common:AgreeAndContinue'),
    onPress: onContinue,
    disabled: !canContinue(),
    testID: 'Agree-Continue-Button',
  }

  const handleOnCancel = (): void => setIsNoAutoPayOverlayVisible(false)

  return (
    <BaseTemplate isLoading={!isQueryDataLoaded || !loan} isError={!!queryError} testID="AcceptACH">
      <Page
        title={t('AcceptAgreements')}
        description={t('AcceptACHDescription')}
        buttonProps={buttonLockupProperties(action)}
        variant={'generic'}
        smallTopGap
      >
        <Box paddingBottom={'medium'}>
          <RadioButtonList
            options={options}
            onPress={(option): void => handleRadioButtonPress(option)}
            selectedOption={selectedAutoPayOption}
          />
        </Box>
        <NoAutoPayOverlay
          isVisible={isNoAutoPayOverlayVisible}
          onConfirmNoAutoPay={handleOnConfirmNoAutoPay}
          onCancel={handleOnCancel}
        />
      </Page>
    </BaseTemplate>
  )
}

export {AcceptACH}
