import React, {FC, useEffect, useState, ReactElement} from 'react'
import {useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {ShowException} from 'src/lib/errors'
import AppEvents from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'
import {allowedStates} from 'src/lib/us_states'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page, {PageProps} from 'src/designSystem/components/organisms/Page/Page'
import {ButtonLockupPropsPrimary} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {
  LoanAmountSelectionContent,
  LoanAmountSelectionContentProps,
} from 'src/products/loans/LoanSelection/LoanAmountSelectionContent/LoanAmountSelectionContent'
import {
  LoanAmountSelectionContentLoanTermsFields,
  OnSelectedLoanAmountChangedCallback,
} from 'src/products/loans/LoanSelection/LoanAmountSelectionContent/LoanAmountSelectionContent.types'

export type LoanAmountSelectionTemplateProps = Pick<
  StackScreenProps<MainStackParamList, 'LoanAmountSelection'>,
  'navigation'
> & {
  loanUsStateAbv: string
  abFlaggedStatesThatCanApply: string[]
  loanTerms: LoanAmountSelectionContentLoanTermsFields
  prequalificationAmount: number | undefined
  onContinueAfterAmountSelected: (args: {selectedLoanAmount: number}) => Promise<void>
  onViewStateFeeSchedules: () => void
} & Pick<LoanAmountSelectionContentProps, 'isRepeatLoan'> // include data needed by child

/**
 * Template for the LoanAmountSelection screen. Renders the screen to select a loan amount
 * with all external data and handler functions passed as props.
 */
const LoanAmountSelectionTemplate: FC<LoanAmountSelectionTemplateProps> = (props) => {
  const {navigation} = props
  const [isLoading, setIsLoading] = useState(false)
  const [selectedLoanAmount, setSelectedLoanAmount] = useState<number>(0)

  const {
    loanUsStateAbv,
    abFlaggedStatesThatCanApply,
    loanTerms,
    prequalificationAmount,
    onContinueAfterAmountSelected,
    onViewStateFeeSchedules,
    isRepeatLoan,
  } = props

  const showPrequalAmount = !!prequalificationAmount

  const {t} = useTranslation(['LoanSelection', 'Common'])

  usePageViewedAnalytics({
    eventName: AppEvents.Name.loan_amount_selection_screen_viewed,
    eventCategory: isRepeatLoan
      ? AppEvents.Category.Reapplication
      : AppEvents.Category.InitialApplication,
  })

  useEffect(() => {
    TrackAppEvent(AppEvents.Name.prequal_loan_slider_experiment_on, AppEvents.Category.Prequal, {
      prequalificationAmount,
    })
  }, [prequalificationAmount])

  const onContinuePress = async (): Promise<void> => {
    try {
      setIsLoading(true)
      if (selectedLoanAmount) {
        await onContinueAfterAmountSelected({
          selectedLoanAmount,
        })
      }
    } catch (e) {
      Log.log('LoanSelection onContinuePress, error:', e)
      ShowException(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOnSelectedAmountChanged: OnSelectedLoanAmountChangedCallback = (args: {
    amount: number
  }): void => {
    const {amount} = args
    setSelectedLoanAmount(amount)
  }

  const getPageContent = (): ReactElement =>
    loanUsStateAbv && !allowedStates(abFlaggedStatesThatCanApply).includes(loanUsStateAbv) ? (
      <PFText variant={'p'} textAlign={'center'}>
        {t('UnsupportedStateMessage')}
      </PFText>
    ) : (
      <LoanAmountSelectionContent
        navigation={navigation}
        prequalificationAmount={prequalificationAmount}
        isDisabled={isLoading}
        loanUsStateAbv={loanUsStateAbv}
        loanTerms={loanTerms}
        isRepeatLoan={isRepeatLoan}
        onSelectedAmountChanged={handleOnSelectedAmountChanged}
      />
    )

  const pageContent = getPageContent()

  const title = showPrequalAmount ? t('CongratsPrequal') : t('HowMuchToBorrow')
  const pageProps: PageProps = {
    variant: 'generic',
    smallTopGap: true,
    title: title,
  }

  const primaryButtonProps: ButtonLockupPropsPrimary = {
    text: t('Common:Continue'),
    onPress: () => onContinuePress(),
    disabled: !loanTerms || isLoading,
    loading: isLoading,
    testID: 'Loan-Amount-Selection-Continue-Btn',
  }

  return loanUsStateAbv === 'TX' ? (
    <Page
      {...pageProps}
      buttonProps={{
        type: 'inlineLink',
        primary: primaryButtonProps,
        inline: {
          text: '',
          buttonText: t('ExampleFeeSchedule'),
          disabled: isLoading,
          onPress: (): void => {
            onViewStateFeeSchedules()
          },
        },
      }}
    >
      {pageContent}
    </Page>
  ) : (
    <Page
      {...pageProps}
      buttonProps={{
        type: 'singleButton',
        primary: primaryButtonProps,
      }}
    >
      {pageContent}
    </Page>
  )
}

export {LoanAmountSelectionTemplate}
