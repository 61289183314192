import React, {FC, useCallback, useEffect, useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useFocusEffect, useNavigation} from '@react-navigation/native'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useCassandraMutation} from '@possible/cassandra/src/utils/hooks'
import {UserGenerateZendeskTokenDocument} from 'src/products/general/ZendeskHelpCenter/queries/UserGenerateZendeskToken.gqls'
import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import List from 'src/designSystem/components/molecules/List/List'
import AppNav from 'src/nav/AppNavActions'
import {isDev} from 'src/lib/utils/environmentUtil'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {ZendeskSSOFormWeb} from 'src/products/general/ZendeskHelpCenter/ZendeskSSOFormWeb'
import {usePfSelector} from 'src/store/utils'
import {isEmailVerifiedSelector} from 'src/lib/user/selector'
import {UnverifiedUserContactUs} from 'src/products/general/ZendeskHelpCenter/UnverifiedUserContactUs'

type Props = StackScreenProps<MainStackParamList, 'ZendeskHelpCenter'>

const zendeskAuthenticationUrlProduction = `https://possiblefinance.zendesk.com/access/jwt?return_to=https://possiblefinance.zendesk.com/hc/en-us/requests/new`
const zendeskAuthenticationUrlSandbox = `https://possiblefinance1722620762.zendesk.com/access/jwt?return_to=https://possiblefinance1722620762.zendesk.com/hc/en-us/`

const Zendesk: FC<Props> = () => {
  const [generateZdToken, {loading: isLoadingZdToken, data: zdTokenData}] = useCassandraMutation(
    UserGenerateZendeskTokenDocument,
  )
  const [zendeskUrl, setZendeskUrl] = useState<string>(zendeskAuthenticationUrlProduction)
  const [isLoadingURL, setIsLoadingURL] = useState<boolean>(false)
  const [shouldRenderForm, setShouldRenderForm] = useState<boolean>(false)

  const navigation = useNavigation()

  useFocusEffect(
    useCallback(() => {
      sendAnalyticEvent(ampli.helpCenterRedirectViewed.bind(ampli))
      if (shouldRenderForm) {
        setShouldRenderForm(false)
      }
      void generateZdToken()
    }, []),
  )

  useEffect(() => {
    const getEnv: () => Promise<void> = async () => {
      setIsLoadingURL(true)
      if ((await isDev()) === true) {
        setZendeskUrl(zendeskAuthenticationUrlSandbox)
      }
      setIsLoadingURL(false)
    }
    void getEnv()
  }, [])

  const renderZendesk: () => void = () => {
    // On web we just submit the form to launch the page in a new tab
    // On mobile we open the zendesk page in a webview
    if (isDeviceWeb()) {
      setShouldRenderForm(true)
    } else {
      AppNav.push(navigation, 'ZendeskSSOForm', {
        token: zdTokenData?.userGenerateZendeskToken.token,
        zendeskUrl,
      })
    }
  }
  const {t} = useTranslation(['ZendeskHelpCenter', 'Common'])

  const action = {
    onPress: renderZendesk,
    text: t('Common:Continue'),
    disabled: isLoadingZdToken || isLoadingURL,
  }

  const listItems = [
    {
      title: t('ListTitle1'),
      text: t('ListText1'),
      name: 'search',
    },
    {
      title: t('ListTitle2'),
      text: t('ListText2'),
      name: 'message',
    },
    {
      title: t('ListTitle3'),
      text: t('ListText3'),
      name: 'chat',
    },
  ]
  if (shouldRenderForm) {
    return (
      <ZendeskSSOFormWeb
        token={zdTokenData?.userGenerateZendeskToken.token}
        zendeskUrl={zendeskUrl}
      />
    )
  }
  return (
    <Page
      variant="generic"
      buttonProps={buttonLockupProperties(action)}
      title={t('HelpCenterTitle')}
      testID="ZendeskHelpCenterPage"
      description={t('HelpCenterDescription')}
      smallTopGap
    >
      <List variant={'icon'} items={listItems} />
    </Page>
  )
}

const ZendeskHelpCenter: FC<Props> = (props) => {
  const isEmailVerified = usePfSelector(isEmailVerifiedSelector)
  if (isEmailVerified) {
    return <Zendesk {...props} />
  } else {
    return <UnverifiedUserContactUs isVisible={!isEmailVerified} />
  }
}

export {ZendeskHelpCenter}
