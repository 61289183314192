import {useCallback} from 'react'

import {useCassandraLazyQuery} from '@possible/cassandra/src/utils/hooks'
import {updateAnalyticsWithCohortData} from 'src/api/actions/card/utils'
import {useUserProperty} from 'src/api/lib/UserProperties/useUserProperty'
import {Consumer, CardAccountStatuses, CohortPhase} from 'src/cassandra'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'
import {useGetCohortUserType} from 'src/products/card/Application/useGetCohortUserType/useGetCohortUserType'
import {useCards} from 'src/products/card/hooks/useCards'
import {isUserEligibleToApply} from 'src/products/card/LoanDash/utils'
import {GetCardCohortPhaseDocument} from 'src/products/loans/Landing/GetCardCohortPhase.gqls'
import {useGetCardsChannel} from 'src/products/loans/Landing/useGetCardsChannel'

/**
 * This hook sets the required user misc props for cards. These include:
 * `possibleCard` - this always set to true
 * `cohortPhase` and `cohortUserType` - these are set on the card landing page
 * If they have already been set, they should not be overwritten
 * This hook also sets the user's `cohortPhase` and `cohortUserType` in Amplitude
 */
type UseInitializeCardsType = () => {
  initializeCards: () => Promise<void>
  isLoading: boolean
}

export const useInitializeCards: UseInitializeCardsType = () => {
  const cohortUserType = useGetCohortUserType()
  const [cardToggleApplicationProperties] =
    Consumer.hooks.useCardAccountToggleApplicationPropertiesMutation()
  const {value: cardUserProperties, isLoading: isLoadingUserProperties} =
    useUserProperty('cardApplicationGroup')
  const {cardAccountPolled, loading: isLoadingUseCards} = useCards()
  const getCardsChannel = useGetCardsChannel()
  const [queryCardCohortPhase] = useCassandraLazyQuery(GetCardCohortPhaseDocument, {
    fetchPolicy: 'network-only',
  })

  // Calls UserMiscProps to set the user cohort data if this data has not already been set
  const initializeCards = useCallback(async () => {
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    const accountStatus = cardAccountPolled?.status as CardAccountStatuses

    try {
      // If the user is rejected + not eligible to reapply, don't enable cards for them
      // This happens for rejected users who click on the deep link again
      if (!isUserEligibleToApply(accountStatus)) {
        return
      }

      // Get cohort from BE
      const {data} = await queryCardCohortPhase({variables: {channel: getCardsChannel()}})
      const cohortPhase = data?.me.products.properties?.cardCohortPhase

      // Add user to cards if a cohort is provided
      const isEnabled = !!cohortPhase && cohortPhase !== CohortPhase.None
      Log.info('Adding user to cards')
      TrackAppEvent(CardEvents.user_added_to_cards, AppEvents.Category.Card)
      await cardToggleApplicationProperties({
        variables: {
          applicationDataInput: {
            enabled: isEnabled,
            miscData: {
              cohortPhase,
              cohortUserType,
            },
            extraFlags: [],
          },
        },
      })

      Log.info('Updating user analytics')

      if (!isLoadingUserProperties && cardUserProperties) {
        updateAnalyticsWithCohortData(cardUserProperties)
      }
    } catch (e) {
      Log.error(e, 'Error setting user cohort properties')
    }
  }, [
    cohortUserType,
    cardAccountPolled,
    cardToggleApplicationProperties,
    cardUserProperties,
    isLoadingUserProperties,
  ])

  return {initializeCards, isLoading: isLoadingUserProperties || isLoadingUseCards}
}
