import React, {FC, useState, useMemo, useEffect} from 'react'
import {StyleSheet, View} from 'react-native'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'
import {useSelector} from 'react-redux'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import BankNumbersForm, {
  AccountInputPropTypes,
  getDefaultFormValues,
  routingNumberFieldName,
  getErrorMessage,
  BankNumbersFormData,
  checkErrors,
} from 'src/products/card/PaymentMethods/BankNumbersForm'
import AppNavActions from 'src/nav/AppNavActions'
import BankAccountAddedModal from 'src/products/card/Common/BankAccountAddedModal'
import {useUser, LinkedAccount, AchPaymentMethod} from 'src/cassandra'
import {snackbarErrorMessage} from 'src/lib/Snackbar/util'
import {getAllPlaidLinkedBankAccountsFilteredByBank} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {PaymentMethodList} from 'src/products/card/PaymentMethods/CardViewPaymentMethods/PaymentMethodList'
import {PaymentAccount, PaymentFlow} from 'src/products/card/PaymentMethods/types'
import {largeGap} from 'src/designSystem/layout'
import {useLinkAchPaymentMethod} from 'src/products/general/GeneralPaymentMethods/useLinkAchPaymentMethod/useLinkAchPaymentMethod'
import Spinner from 'src/products/general/components/atoms/Spinner/Spinner'
import {getActiveAccount, getAllPlaidLinkedBankAccounts} from 'src/lib/card/selectors'
import {useEnablePayOverTime} from 'src/products/card/PaymentMethods/useEnablePayOverTime'
import AppEvents, {CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {logErrorAndShowException} from 'src/lib/errors'

const isDisabled = (
  busy: boolean,
  hasErrors: boolean,
  selectedBankAccount?: LinkedAccount,
): boolean => {
  return busy || hasErrors || !selectedBankAccount
}

type Props = StackScreenProps<MainStackParamList, 'CardVerifyBankDetails'> & {
  bankNameToFilter?: string
  bankIdToFilter?: string
  flow: PaymentFlow
  onComplete: (selectedBankAccount: AchPaymentMethod | undefined) => void | Promise<void>
}

type SubmitData = Record<string, string>

const CardVerifyBankDetails: FC<Props> = (props) => {
  const {navigation, bankNameToFilter, bankIdToFilter, flow, onComplete} = props
  usePageViewedAnalytics({
    eventName: CardEvents.card_verify_bank_details_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {t} = useTranslation(['CardVerifyBankDetails', 'Common'])
  const [bankAccountAddedModalVisible, setBankAccountAddedModalVisible] = useState<boolean>(false)
  useUser(false)
  const cardAccount = useSelector(getActiveAccount)
  const allBanks = useSelector(getAllPlaidLinkedBankAccounts)
  const [selectedBankAccount, setSelectedBankAccount] = useState<LinkedAccount | undefined>()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<AchPaymentMethod | undefined>()
  const linkAchPaymentMethod = useLinkAchPaymentMethod({
    errorMessages: {
      missingAccountId: t('MissingAccountId'),
      unableToCreateAccount: t('UnableToCreateAccount'),
      unableToRetrievePaymentInstrument: t('UnableToRetrieveInstrument'),
      unableToLinkAccount: t('UnableToLinkAccount'),
    },
  })
  const [loading, setLoading] = useState(false)
  const enablePayOverTime = useEnablePayOverTime()

  const addedLinkedAccounts = useMemo(() => {
    return getAllPlaidLinkedBankAccountsFilteredByBank(allBanks, bankNameToFilter, bankIdToFilter)
  }, [allBanks, bankNameToFilter, bankIdToFilter])

  useEffect(() => {
    if (addedLinkedAccounts.length) {
      setSelectedBankAccount(addedLinkedAccounts[0])
    }
  }, [addedLinkedAccounts])

  const requiredAccounts: AccountInputPropTypes[] = useMemo(
    () => [
      {
        id: '1',
        mask: selectedBankAccount?.mask ?? '',
        name: t('AccountNumber'),
      },
    ],
    [t, selectedBankAccount?.mask],
  )

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    control,
    formState: {errors},
    watch,
    handleSubmit,
  } = useForm<BankNumbersFormData>({
    mode: 'all',
    defaultValues: getDefaultFormValues(true, routingNumberFieldName, ''),
  })

  const hasErrors: boolean = checkErrors(true, requiredAccounts, errors, watch)

  const onBankAccountSelect = (bankAccount: PaymentAccount): void => {
    const linkedAccount = bankAccount as LinkedAccount
    TrackAppEvent(
      CardEvents.card_verify_bank_details_payment_account_select,
      AppEvents.Category.Card,
    )
    setSelectedBankAccount(linkedAccount)
  }

  const onSubmit = async (data: SubmitData): Promise<void> => {
    try {
      TrackAppEvent(CardEvents.card_verify_bank_details_submit_cta, AppEvents.Category.Card)
      setLoading(true)
      const {routingNumber} = data

      const newPaymentMethod = await linkAchPaymentMethod({
        routingNumber,
        accountNumber: data['accountNumber-1'],
        linkedAccountId: selectedBankAccount?.id,
        cardAccountId: cardAccount?.id,
        shouldSetSelectedPayNowPaymentMethod: true,
      })
      setSelectedPaymentMethod(newPaymentMethod)
      setBankAccountAddedModalVisible(true)
    } catch (e) {
      snackbarErrorMessage(getErrorMessage(e))
    } finally {
      setLoading(false)
    }
  }

  const onCloseModal = async (): Promise<void> => {
    TrackAppEvent(
      CardEvents.card_verify_bank_details_accounted_added_modal_confirm_cta,
      AppEvents.Category.Card,
    )
    setLoading(true)
    switch (flow) {
      case PaymentFlow.PayNow:
        navigation.navigate('CardAdhocPayment', {screen: 'CardAdhocPaymentReview'})
        break
      case PaymentFlow.PayOverTime: {
        try {
          const params = await enablePayOverTime()
          navigation.push('PayOverTimeConfirmation', params)
        } catch (e) {
          void logErrorAndShowException(e, t('UnableToCreateAccount'))
        }
        break
      }
      case PaymentFlow.Onboarding: {
        await onComplete(selectedPaymentMethod)
        break
      }
      case PaymentFlow.SwitchToAutopay:
        AppNavActions.push(navigation, 'CardReviewAutopay')
        break
    }
    setLoading(false)
  }

  return (
    <Page
      variant={'generic'}
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('VerifyBankDetails'),
          disabled: isDisabled(loading, hasErrors, selectedBankAccount),
          onPress: handleSubmit(onSubmit),
          loading,
        },
      }}
      title={t('WeNeedYourBankDetails')}
      smallTopGap
    >
      {addedLinkedAccounts?.length ? (
        <Box marginTop={'large'}>
          <PaymentMethodList
            paymentMethods={addedLinkedAccounts}
            navigation={navigation}
            selectedPaymentAccount={selectedBankAccount}
            onPaymentAccountSelect={onBankAccountSelect}
            showRadio={addedLinkedAccounts.length > 1}
          />
        </Box>
      ) : (
        <Spinner />
      )}

      <View style={styles.gapView} />

      <BankNumbersForm
        control={control}
        errors={errors}
        showRoutingNumberField={true}
        defaultRoutingNumber={''}
        requiredAccountNumbers={requiredAccounts}
      />

      <BankAccountAddedModal
        visible={bankAccountAddedModalVisible}
        onDismiss={(): void => {
          setBankAccountAddedModalVisible(false)
          void onCloseModal()
        }}
      />
    </Page>
  )
}

export default withForwardedNavigationParams<Props>()(CardVerifyBankDetails)

const styles = StyleSheet.create({
  gapView: {
    marginBottom: largeGap,
  },
})
