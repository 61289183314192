import React, {FC, useCallback, useEffect, useRef} from 'react'
import {useFocusEffect} from '@react-navigation/native'

import {sendAnalyticEvent} from 'src/lib/Analytics/ampli.utils'
import {ampli} from 'src/lib/Analytics/ampli'

type Props = {
  token?: string
  zendeskUrl: string
}

export const ZendeskSSOFormWeb: FC<Props> = (props) => {
  const {token, zendeskUrl} = props

  const formRef = useRef<HTMLFormElement | null>(null)

  useFocusEffect(
    useCallback(() => {
      sendAnalyticEvent(ampli.helpCenterRedirectSelected.bind(ampli))
    }, []),
  )

  useEffect(() => {
    // This behaviour is to submit the form when the component is mounted on web
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [formRef])

  return (
    <form method="post" ref={formRef} action={zendeskUrl}>
      <input type="hidden" name="jwt" value={token} />
    </form>
  )
}
