import {useContext, useCallback} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {CompositeNavigationProp, useNavigation} from '@react-navigation/native'
import {useSelector} from 'react-redux'
import {merge} from 'lodash'

import {Consumer, GetMeAction} from '@possible/cassandra'
import {CardAdhocPaymentQuery} from '@possible/cassandra/src/types/consumer'

import {PaymentReducerAction, PaymentState} from 'src/products/card/AdhocPayment/usePaymentState'
import {CardAdhocPaymentStack} from 'src/products/card/AdhocPayment/CardAdhocPaymentStack'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePfDispatch} from 'src/store/utils'
import {clearSelectedPayNowPaymentMethod} from 'src/lib/card/slice'
import {getPaymentMethodAccount} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {useLinkPaymentMethod} from 'src/products/card/PaymentMethods/useLinkPaymentMethod'
import {CardAdhocPaymentContext} from 'src/products/card/AdhocPayment/useCardAdhocPayment/CardAdhocPaymentProvider'
import {getPayNowPaymentMethod} from 'src/products/card/PaymentMethods/selectors'

type UseCardAdhocPayment = {
  cardAccountId: string
  currentBalance: string
  data: CardAdhocPaymentQuery | undefined
  dispatchPaymentState: React.Dispatch<PaymentReducerAction>
  executePayment: () => Promise<void>
  isLoading: PaymentState['loading']
  userSelectedButtonId: PaymentState['userSelectedButtonId']
  paymentState: PaymentState['paymentArgs']
}

// The plan is to move the logic within this function to the backend.
const getCurrentBalance = (
  activeAccount: CardAdhocPaymentQuery['me']['cardAccounts']['active'] | null,
): string => {
  return activeAccount?.status && 'balance' in activeAccount.status
    ? (activeAccount?.status.balance.ledgerSpentAfterPayments ?? '0')
    : '0'
}

const useCardAdhocPayment = (): UseCardAdhocPayment => {
  const paymentStateContext = useContext(CardAdhocPaymentContext)
  const {data, loading: isLoadingQuery} = Consumer.hooks.useCardAdhocPaymentQuery({
    fetchPolicy: 'cache-first',
  })
  const [schedulePayment] = Consumer.hooks.useCardAccountSchedulePaymentMutation()
  const selectedPaymentMethod = useSelector(getPayNowPaymentMethod)
  const pfDispatch = usePfDispatch()
  const linkPaymentMethod = useLinkPaymentMethod()
  const navigation =
    useNavigation<
      CompositeNavigationProp<
        StackNavigationProp<CardAdhocPaymentStack>,
        StackNavigationProp<MainStackParamList>
      >
    >()

  if (!paymentStateContext) {
    throw new Error('useAdhocPayment has to be used within <AdhocPaymentProvider.Provider>')
  }

  const [paymentState, dispatchPaymentState] = paymentStateContext
  const cardAccountId =
    !isLoadingQuery && data?.me?.cardAccounts.active?.id ? data?.me?.cardAccounts.active?.id : ''

  const executePayment = useCallback(async (): Promise<void> => {
    const variables: PaymentState['paymentArgs'] = {
      ...paymentState.paymentArgs,
      cardAccountId,
      cardPaymentMethodId: selectedPaymentMethod?.id ?? '',
      payment: {
        amount: paymentState.paymentArgs.payment.amount,
        intention: paymentState.paymentArgs.payment.intention,
      },
    }

    try {
      dispatchPaymentState({type: 'setLoading', payload: {loading: true}})

      /* Ensure the default payment method is linked to the card account and is the primary
       * payment method */
      await linkPaymentMethod(getPaymentMethodAccount(selectedPaymentMethod)?.id)

      const {data} = await schedulePayment({variables})

      if (!data?.cardAccountSchedulePayment.id) {
        throw new Error()
      }

      await pfDispatch(GetMeAction())
      pfDispatch(clearSelectedPayNowPaymentMethod())
      // Remove in ENG-17533
      merge(variables, {
        payment: {
          executeDate: data.cardAccountSchedulePayment?.executeAt,
        },
      })

      dispatchPaymentState({type: 'success', payload: {paymentArgs: variables}})

      navigation.navigate('CardAdhocPaymentSuccess')
    } catch (error) {
      dispatchPaymentState({type: 'setLoading', payload: {loading: false}})

      navigation.navigate('CardAdhocPaymentFailure')
    }
  }, [
    paymentState.paymentArgs,
    cardAccountId,
    selectedPaymentMethod,
    dispatchPaymentState,
    linkPaymentMethod,
    schedulePayment,
    pfDispatch,
    navigation,
  ])

  const currentBalance = getCurrentBalance(data?.me?.cardAccounts.active)

  return {
    cardAccountId,
    currentBalance,
    dispatchPaymentState,
    data,
    executePayment,
    paymentState: paymentState.paymentArgs,
    userSelectedButtonId: paymentState.userSelectedButtonId,
    isLoading: isLoadingQuery || paymentState.loading,
  }
}

export {useCardAdhocPayment}
