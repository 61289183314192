import React, {useState, FC} from 'react'
import {useForm} from 'react-hook-form'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

import GenericNonModalTemplate from 'src/designSystem/components/templates/GenericNonModalTemplate/GenericNonModalTemplate'
import HookForm, {FieldVariants} from 'src/designSystem/components/atoms/HookForm/HookForm'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {getHasErrorsOrMissingValues, validEmail} from 'src/lib/utils/formValidationUtil'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import PFEmailInput from 'src/designSystem/components/molecules/PFEmailInput/PFEmailInput'
import {ShowException} from 'src/lib/errors'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {usePfDispatch} from 'src/store/utils'
import {buttonLockupProperties} from 'src/designSystem/components/templates/GenericNonModalTemplate/utils'
import {onboardingEmailCodeSent} from 'src/lib/onboarding/slice'
import {PushPage} from 'src/navigation/NavHelper'
import {onEditEmailVerificationSuccess} from 'src/products/MCU/EmailConfirmation/EmailConfirmation.utils'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {UserModifyEmailMutation} from '@possible/cassandra/src/user/authPrivateMethods'
import {
  ModifyEmailInput,
  ModifyEmailSendCodeMedium,
} from '@possible/cassandra/src/types/types.mobile.generated'

type FormData = {
  newEmail: string
  confirmNewEmail: string
}

type Props = StackScreenProps<MainStackParamList, 'EditEmail'>

const EditEmail: FC<Props> = ({navigation, route}: Props) => {
  const {t} = useTranslation(['EmailPasswordManagement', 'Common'])

  const [busy, setBusy] = useState(false)
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    trigger,
  } = useForm<FormData>({mode: 'onChange'})

  const dispatch = usePfDispatch()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.edit_email_screen_viewed,
    eventCategory: AppEvents.Category.Admin,
  })

  const onSubmit = async (data: FormData): Promise<void> => {
    setBusy(true)
    try {
      const input: ModifyEmailInput = {
        proposedEmail: data?.newEmail,
        verificationCodeMedium: ModifyEmailSendCodeMedium.EmailCode,
      }
      const res: boolean | undefined = await UserModifyEmailMutation(input)

      if (res) {
        TrackAppEvent(AppEvents.Name.edit_email_submitted, AppEvents.Category.Admin)
        //need to make sure we have the updated email for the next screen
        await dispatch(UserStateRefresh())
        dispatch(onboardingEmailCodeSent())
      }
      if (route?.params?.fromProfile) {
        PushPage(navigation, 'EmailVerification', {
          onSuccess: () => {
            onEditEmailVerificationSuccess(navigation)
          },
        })
      } else {
        navigation.pop()
      }
    } catch (e) {
      ShowException(e)
    } finally {
      setBusy(false)
    }
  }

  const getDisabledAction = (): boolean => {
    return getHasErrorsOrMissingValues(errors, watch) || busy
  }

  const action = {
    text: t('Common:Submit'),
    onPress: handleSubmit(onSubmit),
    disabled: getDisabledAction(),
  }

  const validateEmailFields = (
    value: string | undefined,
    validateMatch: boolean,
  ): string | undefined => {
    if (validateMatch && value !== watch('newEmail')) {
      return t('EmailsMustMatch')
    }
    if (!validEmail(value)) {
      return t('Common:InvalidEmailError')
    }
    return undefined
  }

  const formProps = {
    newEmail: {
      name: 'newEmail',
      field: FieldVariants.TextField,
      rules: {
        required: t('EmailRequiredError'),
        validate: (value: string) => validateEmailFields(value, false),
      },
      onChange: trigger('confirmNewEmail'),
    },
    confirmNewEmail: {
      name: 'confirmNewEmail',
      field: FieldVariants.TextField,
      rules: {
        required: t('EmailConfirmationRequiredError'),
        validate: (value: string) => validateEmailFields(value, true),
      },
    },
  }

  const getContent = (): React.ReactNode => {
    return (
      <HookForm control={control} errors={errors}>
        <PFEmailInput label={t('NewEmail')} formProps={formProps.newEmail} />
        <PFEmailInput label={t('ConfirmNewEmail')} formProps={formProps.confirmNewEmail} />
      </HookForm>
    )
  }

  return (
    <GenericNonModalTemplate
      actionBlock={buttonLockupProperties(action)}
      title={t('EditEmailTitle')}
      description={t('EditEmailDescription')}
    >
      {getContent()}
    </GenericNonModalTemplate>
  )
}

export default withForwardedNavigationParams<Props>()(EditEmail)
