import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'

type NavContainerType = 'NavPageState' | 'Workflows'

export const CALoanFinalActivationNavigationScreenAnalytics = (
  navContainerType: NavContainerType,
): void => {
  usePageViewedAnalytics({
    eventName: AppEvents.Name.review_payment_schedule_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: navContainerType,
    },
  })
  usePageViewedAnalytics({
    eventName: AppEvents.Name.tila_disclosure_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: navContainerType,
    },
  })
  usePageViewedAnalytics({
    eventName: AppEvents.Name.final_loan_acceptance_screen_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: navContainerType,
    },
  })
}
