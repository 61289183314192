import {StackScreenProps} from '@react-navigation/stack'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Image, ScrollView, StyleSheet} from 'react-native'
import {SafeAreaView} from 'react-native-safe-area-context'
import {withForwardedNavigationParams} from 'react-navigation-props-mapper'

import {NamedColors} from 'src/designSystem/colors'
import {appInit, userIsLoggedIn, userIdSelector} from 'src/api/selectors/selectors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import ButtonLockup, {
  ButtonLockupProps,
  ButtonLockupPropsPrimary,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import Log from 'src/lib/loggingUtil'
import {clearDeeplink, getDeeplink} from 'src/lib/singular/utils'
import {isDeviceWeb} from 'src/lib/utils/platform'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {usePfSelector} from 'src/store/utils'
import {useInitializeCards} from 'src/products/loans/Landing/useInitializeCards'
import CardLandingSplash from 'src/assets/images/card-landing-splash.png'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'

type Props = StackScreenProps<MainStackParamList, 'Landing'>

const CardLanding: React.FC<Props> = (props) => {
  const {navigation} = props
  const {t} = useTranslation('OpeningLandingPage')
  const initComplete = usePfSelector(appInit)
  const loggedIn = usePfSelector(userIsLoggedIn)
  const userId = usePfSelector(userIdSelector)
  const [busy, setBusy] = useState(false)
  const {initializeCards} = useInitializeCards()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_landing_screen_viewed,
    eventCategory: AppEvents.Category.Onboarding,
  })
  useEffect(() => {
    if (!initComplete) {
      return
    }

    if (loggedIn && userId) {
      const doIt = async (): Promise<void> => {
        const cardDeeplink = getDeeplink('card_landing')
        if (cardDeeplink) {
          setBusy(true)

          try {
            await initializeCards()
            await clearDeeplink(cardDeeplink)
          } catch (e) {
            Log.error(e, 'Error adding user to card group.')
          } finally {
            setBusy(false)
          }
        }
      }

      void doIt()
    }
  }, [initComplete, loggedIn, userId, initializeCards])

  const onLogin = (signUp: boolean): void => {
    PushPage(navigation, 'Login', {signUp})
  }

  const loggedInAction: ButtonLockupPropsPrimary = {
    text: t('Continue'),
    onPress: () => {
      setBusy(true)
      setBusy(false)
    },
    disabled: busy,
    testID: 'OnePageLandingContinueButton',
  }

  const loggedOutAction: ButtonLockupPropsPrimary = {
    text: isDeviceWeb() ? t('PrimaryButtonNewWeb') : t('PrimaryButtonNew'),
    onPress: () => {
      onLogin(true)
    },
    disabled: false,
    testID: 'OnePageLandingGetStartedButton',
  }

  const secondary: ButtonLockupPropsSecondary = {
    buttonText: t('SecondaryButtonAlt'),
    size: 'small',
    onPress: () => {
      onLogin(false)
    },
    disabled: false,
    testID: 'OnePageLandingAlreadyHaveAnAccountButton',
  }

  const loggedOutButtonBlock: ButtonLockupProps = {
    type: 'doubleButton',
    primary: loggedOutAction,
    secondary: secondary,
  }

  const loggedInButtonBlock: ButtonLockupProps = {
    type: 'singleButton',
    primary: loggedInAction,
  }

  const userButtonLockUp = loggedIn ? loggedInButtonBlock : loggedOutButtonBlock
  const safeAreaViewStyle = {flex: 1}
  return (
    <Box fill alignSelf="center" boxStyle={styles.container}>
      <SafeAreaView edges={['top', 'right', 'left']} style={safeAreaViewStyle}>
        <ScrollView showsVerticalScrollIndicator>
          <Box fill gap="little" padding="medium" marginTop="large">
            <Box gap="small">
              <Box align="center">
                <Box
                  align="center"
                  paddingHorizontal="little"
                  paddingTop={isDeviceWeb() ? 'tiny' : 'large'}
                  paddingBottom={'small'}
                >
                  <PFText variant="h3" textAlign="center">
                    {isDeviceWeb() ? null : t('CardLandingTitle')}
                  </PFText>
                </Box>

                <Box align="center" paddingHorizontal="little" paddingBottom="little">
                  <PFText variant="p_sm" color={NamedColors.SILVER}>
                    {isDeviceWeb() ? null : t('CardLandingSubTitle')}
                  </PFText>
                </Box>

                <Image
                  source={CardLandingSplash}
                  style={isDeviceWeb() ? styles.webImage : undefined}
                />

                {!isDeviceWeb() ? (
                  <PFText variant="p_sm" color="link">
                    {t('CardLandingSwipeUp')}{' '}
                    <SvgIcon name="arrowUp" size="little" colorVariant="info" />
                  </PFText>
                ) : (
                  <PFText variant="h3" color="link" textAlign="center">
                    {t('CardLandingSwipeUpWeb')}
                  </PFText>
                )}
              </Box>
            </Box>

            <Box gap="medium" padding="medium">
              {!isDeviceWeb() ? (
                <PFText variant="p_lg_semibold">{t('CardLandingMotivator')}</PFText>
              ) : null}

              <Box gap="medium">
                <Box direction="row" gap="medium" align="center">
                  <SvgIcon name="scale" size="large" colorVariant="success" />
                  <PFText variant="p">{t('CardLandingItem1')}</PFText>
                </Box>
                {!isDeviceWeb() ? (
                  <Box direction="row" gap="medium" align="center">
                    <SvgIcon name="checkWithCircle" size="large" colorVariant="success" />
                    <PFText variant="p">{t('CardLandingItem2')}</PFText>
                  </Box>
                ) : null}

                <Box direction="row" gap="medium" align="center">
                  <SvgIcon name="money" size="large" colorVariant="success" />
                  <PFText variant="p">{t('CardLandingItem3')}</PFText>
                </Box>

                <Box direction="row" gap="medium" align="center">
                  <SvgIcon name="clock" size="large" colorVariant="success" />
                  <PFText variant="p">{t('CardLandingItem4')}</PFText>
                </Box>
              </Box>
            </Box>
          </Box>
        </ScrollView>
        {initComplete ? (
          <Box width="100%" paddingBottom="medium" elevation={isDeviceWeb() ? undefined : 6}>
            <ButtonLockup {...userButtonLockUp} />
          </Box>
        ) : null}
      </SafeAreaView>
    </Box>
  )
}

export default withForwardedNavigationParams<Props>()(CardLanding)
export const contentMaxWidth: number = 450 as const

const styles = StyleSheet.create({
  container: {
    maxWidth: contentMaxWidth,
  },
  webImage: {
    height: 124,
    marginBottom: 16,
    width: 152,
  },
})
