import React from 'react'

import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {
  ConfirmBankAccountGQLContainer,
  ConfirmBankAccountGQLContainerProps,
} from 'src/products/loans/LoanApprovedActivation/ConfirmBankAccount/ConfirmBankAccountGQLContainer'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export type ConfirmBankAccountWorkflowContainerProps = WorkflowPreReqFulfillScreenProps

/**
 * Container to integrate the ConfirmBankAccount into a workflows.
 */
export const ConfirmBankAccountWorkflowContainer: React.FC<WorkflowPreReqFulfillScreenProps> = (
  props,
) => {
  const {onPreReqFulfilled} = props

  usePageViewedAnalytics({
    eventName: AppEvents.Name.confirm_bank_account_viewed,
    eventCategory: AppEvents.Category.Checkout,
    eventArgs: {
      navContainerType: 'Workflows',
    },
  })

  const handleOnSubmit: ConfirmBankAccountGQLContainerProps['onSubmit'] = async () => {
    TrackAppEvent(AppEvents.Name.confirm_bank_account_completed, AppEvents.Category.Checkout, {
      navContainerType: 'Workflows',
    })

    await onPreReqFulfilled()
  }

  return <ConfirmBankAccountGQLContainer onSubmit={handleOnSubmit} />
}
