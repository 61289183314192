import {StackScreenProps} from '@react-navigation/stack'
import React from 'react'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import NavPageState from 'src/navigation/NavPageState'
import {LoanDisbursementMethodSelectionGQLContainer} from 'src/products/loans/LoanApprovedActivation/LoanDisbursementMethodSelection/LoanDisbursementMethodSelectionGQLContainer'
import {DisbursementMethodSelection as StepName} from 'src/navigation/LoanApprovedFlow'
import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'

export type LoanDisbursementMethodSelectionNavPageStateContainerProps = Pick<
  StackScreenProps<MainStackParamList, 'DisbursementMethodSelection'>,
  'navigation'
>

/**
 * NavPageState container for the LoanDisbursementMethodSelection screen to integrate it into
 * the NavPageState nav system.
 */
export const LoanDisbursementMethodSelectionNavPageStateContainer: React.FC<
  LoanDisbursementMethodSelectionNavPageStateContainerProps
> = (props) => {
  const {navigation} = props
  usePageViewedAnalytics({
    eventCategory: AppEvents.Category.Checkout,
    eventName: AppEvents.Name.loan_disbursement_method_selection_screen_viewed,
    eventArgs: {
      navContainerType: 'NavPageState',
    },
  })

  const handleOnContinue = async (): Promise<void> => {
    try {
      const nextPage = NavPageState.GetNextApprovalState(StepName)
      await NavPageState.GoToScreen(navigation, nextPage)
    } catch (e) {
      void logOfferActivationErrorAndShowException(
        e,
        'LoanDisbursementMethodSelectionNavPageStateContainer handleOnContinue() failed',
      )
    }
  }

  return <LoanDisbursementMethodSelectionGQLContainer onContinue={handleOnContinue} />
}
