import React, {useState} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import AppNav from 'src/nav/AppNavActions'
import {usePfDispatch} from 'src/store/utils'
import {getPotentialAutopaySchedule} from 'src/lib/card/selectors'
import {GetMeAction} from 'src/cassandra'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import AutoPayIcon from 'src/assets/illustrations/Autopay.svg'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {formatDate, friendlyDateWithDOW, utcDate} from 'src/lib/utils/date'
import {useGetPaymentFrequency} from 'src/products/card/Autopay/useGetPaymentFrequency'
import {useCards} from 'src/products/card/hooks/useCards'
import {NamedColors} from 'src/designSystem/colors'

type Props = StackScreenProps<MainStackParamList, 'CardSignedUpAutopay'>

const CardSignedUpAutopay: React.FC<Props> = (props) => {
  const {t} = useTranslation(['CardSignedUpAutopay', 'Common'])
  const frequency = useGetPaymentFrequency()
  const schedule = useSelector(getPotentialAutopaySchedule)
  const dispatch = usePfDispatch()
  const [busy, setBusy] = useState(false)
  const {refetchCardsQuery} = useCards()

  const startingOn = formatDate(schedule?.[0]?.paymentDate, friendlyDateWithDOW, utcDate)

  const onPress = async (): Promise<void> => {
    setBusy(true)
    await dispatch(GetMeAction())
    refetchCardsQuery()
    AppNav.PopToName(props.navigation, 'CardDashboard')
  }

  return (
    <Page
      variant="generic"
      title={t('YouHaveSignedUp')}
      testID="You-Have-SignedUp-Id"
      smallTopGap
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('Common:Ok'),
          onPress,
          disabled: busy,
          loading: busy,
        },
      }}
    >
      <Box align="center">
        <Box marginTop="little" marginBottom="large">
          <PFSvg SvgUri={AutoPayIcon} svgProps={{width: 191, height: 157}} />
        </Box>
        <Box direction="row" alignSelf="center" marginBottom="little">
          <PFText variant="p">{t('YourAutopayWillBePaid')}</PFText>
          <PFText variant="p">{frequency}</PFText>
          <PFText variant="p">{t('StartingOn')}</PFText>
        </Box>
        <PFText variant="p_lg_semibold">{startingOn}</PFText>
        <Box padding={'small'}>
          <PFText variant="p" textAlign="center" color={NamedColors.SILVER}>
            {t('NotTakeEffect')}
          </PFText>
        </Box>
      </Box>
    </Page>
  )
}

export default CardSignedUpAutopay
