import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type LoanSubmissionQueryVariables = Types.Exact<{[key: string]: never}>

export type LoanSubmissionQuery = {
  __typename?: 'Query'
  getCurrentOffer?: {__typename?: 'Offer'; id: string} | null
  me: {
    __typename?: 'User'
    id: string
    identification?: {
      __typename?: 'IssuedIdentificationInfo'
      ssn?: {__typename?: 'SSN'; mask: string} | null
    } | null
    bankAccounts: {
      __typename?: 'LinkedAccountCollection'
      all?: Array<{
        __typename: 'LinkedAccount'
        name?: string | null
        mask: string
        preferredFundingSource?: boolean | null
      }> | null
    }
    profile?: {
      __typename?: 'UserProfile'
      birthDate?: string | null
      name?: {__typename?: 'Name'; firstName: string; lastName?: string | null} | null
      home?: {
        __typename?: 'Location'
        address?: {
          __typename?: 'Address'
          street1?: string | null
          street2?: string | null
          city?: string | null
          state?: string | null
          postalCode?: string | null
          verified: boolean
        } | null
      } | null
      email?: {__typename?: 'Email'; address?: string | null} | null
    } | null
    onboarding?: {
      __typename?: 'OnboardingInfo'
      loan?: {__typename?: 'LoanOnboardingInfo'; amountSelected?: string | null} | null
    } | null
    loans: {
      __typename?: 'UserLoanCollection'
      countApplied: number
      latestActionableLoan?: {
        __typename?: 'Loan'
        id: string
        originator: Types.LoanOriginator
      } | null
    }
  }
}

export type PartnerStoreProductAttributionMutationVariables = Types.Exact<{
  offerId: Types.Scalars['UUID']['input']
  entityId: Types.Scalars['UUID']['input']
}>

export type PartnerStoreProductAttributionMutation = {
  __typename?: 'Mutation'
  partnerStoreProductAttribution: boolean
}

export const LoanSubmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'LoanSubmission'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getCurrentOffer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'identification'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'ssn'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'mask'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'bankAccounts'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'all'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'mask'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'preferredFundingSource'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'profile'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'name'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'firstName'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lastName'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'birthDate'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'home'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'address'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'street1'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'street2'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'city'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'state'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'postalCode'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'verified'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'email'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'address'}}],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'onboarding'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'loan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'amountSelected'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'loans'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'countApplied'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'latestActionableLoan'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'originator'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoanSubmissionQuery, LoanSubmissionQueryVariables>
export const PartnerStoreProductAttributionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'PartnerStoreProductAttribution'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'offerId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'entityId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'UUID'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'partnerStoreProductAttribution'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'offerId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'offerId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'entityId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'entityId'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PartnerStoreProductAttributionMutation,
  PartnerStoreProductAttributionMutationVariables
>
