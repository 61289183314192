import {
  AchPaymentMethod,
  CheckPaymentMethod,
  DebitCardPaymentMethod,
  LoanDocumentType,
} from '@possible/cassandra/src/types/types.mobile.generated'

/**
 * The different payment method types that can be used to accept
 * the autopay agreement.
 */
export type AcceptAgreementsPaymentMethodTypeName =
  | NonNullable<AchPaymentMethod['__typename']>
  | NonNullable<DebitCardPaymentMethod['__typename']>
  | NonNullable<CheckPaymentMethod['__typename']>

/**
 * The different types of agreements that can be accepted. Note that there is a BE
 * type LoanDocumentType but it doesn't include all of these so FE has its own definition.
 */
export enum AgreementType {
  LoanAgreement = 'loan_agreement',
  CreditServicesDisclosureStatement = 'credit_services_disclosure_statement',
  CreditServicesAgreement = 'credit_services_agreement',
  ArbitrationAgreement = 'abritration_agreement',
  StatutoryNotice = 'statutory_notice',
}

/**
 * A single state-specific agreement. For example, an arbitration agreement in FL
 * or credit services agreement in TX.
 */
export type StateSpecificAgreement = {
  title: string
  /**
   * The type of document that should be downloaded when the user clicks to view the agreement. This may be different
   * than the agreementType if there are multiple agreementTypes in a single document PDF.
   */
  documentTypeToDownload: LoanDocumentType
  agreementType: AgreementType
  /**
   * Each agreement must have a unique ID.
   */
  id: string
}

/**
 * The result of a state-specific agreement, including whether it was accepted by the user.
 */
export type StateSpecificAgreementResult = StateSpecificAgreement & {
  didUserAccept: boolean
}

/**
 * Result representing the state of the agreements after the user has
 * accepted them and continued.
 */
export type AcceptAgreementsContinueResult = {
  didAcceptLoanAgreement?: boolean
  didAcceptAutoPayAgreement?: boolean
  stateSpecificAgreements?: StateSpecificAgreementResult[]
  setDisbursementMethodToCheck?: boolean
}
