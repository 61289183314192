import React, {useState} from 'react'
import {StyleSheet} from 'react-native'

import {AdverseReasonCode} from '@possible/cassandra/src/types/types.iam.generated'

import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents, {LoanDashboardEvents} from 'src/lib/Analytics/app_events'
import {ConfirmDeclineCounterOfferOverlay} from 'src/products/loans/Dashboard/LoanCounterOfferTile/ConfirmDeclineCounterOfferOverlay/ConfirmDeclineCounterOfferOverlay'
import {getAdverseReasonDescription} from 'src/products/loans/LoanProcessing/LoanProcessing.utils'
import {LoanProcessingCounterOfferTemplateInner} from 'src/products/loans/LoanProcessing/LoanProcessingCounterOffer/LoanProcessingCounterOfferTemplate'

export type LoanCounterOfferTileProps = {
  onViewCounterOffer: () => void
  onDeclineCounterOffer: () => void
  onContinueWithCounterOffer: () => void
  amountApproved: number
  amountRequested: number
  testID?: string
  firstName: string
  counterOfferCodes: (AdverseReasonCode | null)[]
}

const LoanCounterOfferTile: React.FC<LoanCounterOfferTileProps> = (
  props: LoanCounterOfferTileProps,
) => {
  const {
    firstName,
    counterOfferCodes,
    onViewCounterOffer: handleOnViewCounterOffer,
    onDeclineCounterOffer,
    onContinueWithCounterOffer,
    amountApproved,
    amountRequested,
    testID = 'LoanCounterOfferTile',
  } = props

  const [showConfirmDeclineCounterOfferOverlay, setShowConfirmDeclineCounterOfferOverlay] =
    useState(false)
  const isAtLeastHalfApproved = amountApproved >= amountRequested / 2

  const handleOnDeclineCounterOffer = (): void => {
    TrackAppEvent(
      LoanDashboardEvents.approved_decline_counter_offer_selected,
      AppEvents.Category.LoanDashboard,
    )
    setShowConfirmDeclineCounterOfferOverlay(true)
  }

  const handleOnContinueWithCounterOffer = (): void => {
    onContinueWithCounterOffer()
    setShowConfirmDeclineCounterOfferOverlay(false)
  }

  const handleOnConfirmCancel = (): void => {
    onDeclineCounterOffer()
    setShowConfirmDeclineCounterOfferOverlay(false)
  }

  const reason = getAdverseReasonDescription(counterOfferCodes[0] ?? undefined)

  return (
    <BasicTile padding="medium" testID={testID}>
      <LoanProcessingCounterOfferTemplateInner
        firstName={firstName}
        isOnContinueLoading={false}
        onCancel={handleOnDeclineCounterOffer}
        onContinue={handleOnViewCounterOffer}
        amountBorrowed={amountApproved.toString()}
        amountRequested={amountRequested.toString()}
        isAmountApprovedOverOrEqualFiftyPercent={isAtLeastHalfApproved}
        reason={reason}
      />

      <ConfirmDeclineCounterOfferOverlay
        onConfirmCancel={handleOnConfirmCancel}
        onContinueWithLoan={handleOnContinueWithCounterOffer}
        showCancelLoanConfirmation={showConfirmDeclineCounterOfferOverlay}
      />
    </BasicTile>
  )
}

export {LoanCounterOfferTile}

const styles = StyleSheet.create({
  infoTileContainer: {
    borderWidth: 0,
    marginHorizontal: 0,
  },
})
