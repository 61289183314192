import React from 'react'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {usePageViewedAnalytics} from 'src/lib/Analytics/AnalyticsHelper'
import AppEvents from 'src/lib/Analytics/app_events'
import {CardAutopayOverviewTemplate} from 'src/products/card/Activation/CardAutopayOverview/CardAutopayOverviewTemplate/CardAutopayOverviewTemplate'
import {useCards} from 'src/products/card/hooks/useCards'

type CardAutopayOverviewNavigationContainerProps = {
  onComplete: () => Promise<void>
}

const CardAutopayOverviewNavigationContainer = ({
  onComplete,
}: CardAutopayOverviewNavigationContainerProps): JSX.Element => {
  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_join_autopay_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {hasPendingPhysicalCard} = useCards()

  const handleOnPress = async (): Promise<void> => {
    TrackAppEvent(AppEvents.Name.card_autopay_overview_page_cta, AppEvents.Category.Card)

    await onComplete?.()
  }

  return (
    <CardAutopayOverviewTemplate
      onPress={handleOnPress}
      hasPendingPhysicalCard={hasPendingPhysicalCard}
    />
  )
}

export {CardAutopayOverviewNavigationContainer}
