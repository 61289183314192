import {ApolloQueryResult} from '@apollo/client'
import {ParamListBase, RouteProp} from '@react-navigation/native'
import {StackNavigationOptions, StackNavigationProp} from '@react-navigation/stack'
import {isEqual} from 'lodash'
import {useCallback, useEffect, useState} from 'react'

import {Consumer} from '@possible/cassandra'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import {EmitRedirectionEvent} from 'src/lib/utils/events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {HeaderButtonOptionFactory} from 'src/workflows/buttons'
import {WorkflowDescriptor, WorkflowHeaderButtonMap, WorkflowRoutes} from 'src/workflows/constants'
import {useWFLogging, wfDebug, wfError, wfLog, wfWarn} from 'src/workflows/logging'
import {
  ApplicationActivationWorkflowStackParams,
  FrontEndPreReqType,
  MoveToNextPreReqRouteArgsType,
  OfferApplicationWorkflowStackParams,
  PreReqType,
  SelectedOfferInformation,
  SignupWorkflowStackParams,
  WorkflowsPages,
  WorkflowsStackParams,
} from 'src/workflows/types'
import {
  GetMetPreReqsForOfferId,
  GetNextPreReq,
  GetNextRouteFromPreReqs,
  GetRouteHistoryFromPreReqs,
  GetUnmetPreReqsForOfferId,
  MapPreReqToRoute,
  ShouldOfferPreReqBeFulfilledExclusivelyOnWeb,
} from 'src/workflows/workflow.utils'

const CurrentAndNextRouteAreEqualErrorMessage =
  'Oops! Double check you have entered all information.'

/**
 * A selector function that returns the unmet pre-reqs from the offersMinPreReqs
 * field of the `useProductsQuery` response.
 * @param response A response from `useProductsQuery`.
 * @returns The unmet min pre-reqs.
 */
const OffersMinPreReqsNotMetSelector = (
  response: ApolloQueryResult<Consumer.types.ProductsQuery>,
): {met: PreReqType[]; unmet: PreReqType[]} => {
  return {
    unmet: response.data.me.products.offersMinPreReqs.requirementNotMet,
    met: response.data.me.products.offersMinPreReqs.requirementMet,
  }
}

/**
 * A selector generating function that returns a selector to return the
 * unmet pre-reqs from the offer's pre-reqs.
 * @param selectedOfferId The ID of the offer.
 * @param remainingFrontEndPreReqs The remaining pre-reqs that are not met.
 * @returns A selector function that returns the unmet offer pre-reqs.
 */
const SelectedOfferPreReqsNotMetSelectorFactory = (
  selectedOfferId: string,
  remainingFrontEndPreReqs: FrontEndPreReqType[],
) => {
  /**
   * A selector function that returns the unmet pre-reqs from the offer's pre-reqs.
   * @param response A response from `useProductsQuery`.
   * @returns The unmet offer pre-reqs.
   */
  return (
    response: ApolloQueryResult<Consumer.types.ProductsQuery>,
  ): {met: PreReqType[]; unmet: PreReqType[]} => {
    const unmetOfferPreReqs = GetUnmetPreReqsForOfferId(
      selectedOfferId,
      response.data.me.products.eligible,
    )
    const metOfferPreReqs = GetMetPreReqsForOfferId(
      selectedOfferId,
      response.data.me.products.eligible,
    )
    return {
      unmet: [...(unmetOfferPreReqs ?? []), ...remainingFrontEndPreReqs],
      met: metOfferPreReqs ?? [],
    }
  }
}

/**
 * A method to automatically navigate to the next pre-req route.
 * Or, if there are no more pre-reqs, navigate to the dashboard.
 * @param navigation The main stack navigation object.
 * @param refetch The refetch function from `useProductsQuery`.
 * @param preReqSelector  A selector function that returns an array of pre-reqs from the `useProductsQuery` response.
 * @returns Nothing.
 */
const MoveToNextPreReqRoute = async <
  T extends ApolloQueryResult<unknown> = ApolloQueryResult<Consumer.types.ProductsQuery>,
>(
  args: MoveToNextPreReqRouteArgsType<T>,
): Promise<NonNullable<MoveToNextPreReqRouteArgsType['previousPreReqs']>> => {
  const {navigation, refetch, preReqSelector, selectedOffer, onAllPreReqsMet, previousPreReqs} =
    args

  try {
    const result = await refetch()
    if (result.error) {
      wfDebug('Error while refetching products query')
      throw result.error
    }

    if (result.errors) {
      wfDebug('Errors while refetching products query')
      throw result.errors[0]
    }

    wfDebug(`Refetched products query: \n${JSON.stringify(result.data, null, 2)}`)

    const currentRouteName = GetCurrentRouteName(navigation.getState())
    const currentPreReq = GetPreReqFromRouteName(currentRouteName)
    const {unmet, met} = preReqSelector(result)

    // remove pre-reqs from 'met' that should not be
    // revisited when stepping back (forward) through the workflow
    const revisitableMet = previousPreReqs?.met.filter(
      (m) => WorkflowDescriptor[m] && !WorkflowDescriptor[m]?.shouldExcludeFromNavigationHistory,
    )
    const nextPreReq = GetNextPreReq(unmet, currentPreReq, revisitableMet)
    const nextRoute = MapPreReqToRoute(nextPreReq)

    wfDebug(
      `\n\nMoveToNextPreReqRoute data:\n${JSON.stringify({currentRouteName, currentPreReq, unmet, met, revisitableMet, nextPreReq, nextRoute})}\n\n`,
    )
    if (nextPreReq && !nextRoute) {
      wfWarn(`No known route for pre-req: ${JSON.stringify(nextPreReq)}`)
    }

    if (!nextPreReq || !nextRoute) {
      TrackAppEvent(AppEvents.Name.workflow_pre_reqs_complete, AppEvents.Category.Workflows)
      if (onAllPreReqsMet && (await onAllPreReqsMet())) {
        wfDebug('No more pre-reqs to fulfill. using onAllPreReqsMet() to determine what to do next')
        // if a workflow navigator stack wants to provide custom behavior when all prereqs are
        // met we will do that instead of the default of going to ProductHub
        return {met, unmet}
      }
      wfDebug('No more pre-reqs to fulfill. Going to ProductHub.')
      // by default we navigate to the product hub if there are no more pre-reqs
      navigation.reset({routes: [{name: 'ProductHub'}], index: 0})
      return {met, unmet}
    }

    // some pre-reqs can only be fulfilled on the web
    // if we encounter one of these, we should redirect the user to the web
    if (ShouldOfferPreReqBeFulfilledExclusivelyOnWeb(nextPreReq, selectedOffer)) {
      wfDebug('Pre-req can only be fulfilled on the web. Redirecting to the web.')
      EmitRedirectionEvent('onboarding', true)
      return {met, unmet}
    }

    if (nextRoute === currentRouteName) {
      wfDebug('Next route is the same as the current route. Doing nothing.')
      throw new Error(CurrentAndNextRouteAreEqualErrorMessage)
    }

    const parentState = navigation.getState()
    const parentRouteName = parentState?.routes[parentState.index].name

    if (parentRouteName) {
      wfDebug(`Navigating to next pre-req route: ${nextRoute}`)

      TrackAppEvent(AppEvents.Name.workflow_navigation, AppEvents.Category.Workflows, {
        from: currentRouteName,
        to: nextRoute,
        remaining: unmet,
      })

      // @ts-expect-error We know that the parent route name is in the main stack param list.
      navigation.navigate(parentRouteName, {screen: nextRoute})
    } else {
      wfWarn(`Parent route name not found. Don't know where to go.`)
      TrackAppEvent(AppEvents.Name.workflow_unknown_destination, AppEvents.Category.Workflows)
    }

    return {met, unmet}
  } catch (e) {
    if (e instanceof Error) {
      TrackAppEvent(AppEvents.Name.workflow_navigation_failed, AppEvents.Category.Workflows, {
        reason: e.message,
      })

      if (e.message !== CurrentAndNextRouteAreEqualErrorMessage) {
        wfError(e, 'Error while moving to next pre-req route')
      }
    }

    ShowException(e)
  }

  return {met: [], unmet: []}
}

const GetCurrentRouteName = (
  state: ReturnType<StackNavigationProp<MainStackParamList>['getState']>,
): WorkflowsPages | undefined => {
  const topRouteName = state.routes[state.index].name

  if (WorkflowRoutes.includes(topRouteName)) {
    wfLog('Current route is a workflow route. Returning nested route name.')
    const nestedState = state.routes[state.index].state
    wfLog('nestedState', JSON.stringify(nestedState))
    if (nestedState) {
      const nestedIndex = nestedState.index
      if (nestedIndex !== undefined) {
        const nestedRouteName = nestedState.routes[nestedIndex].name
        wfLog('nestedRouteName', nestedRouteName)
        // eslint-disable-next-line no-type-assertion/no-type-assertion
        return nestedRouteName as WorkflowsPages
      }
    }
  }

  wfWarn('Current route is not a workflow route. Returning undefined.')
  return undefined
}

const GetPreReqFromRouteName = (routeName: WorkflowsPages | undefined): PreReqType | undefined => {
  if (!routeName) {
    return undefined
  }

  // when will TS support this? aaargh
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  return Object.keys(WorkflowDescriptor).find(
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    (key) => WorkflowDescriptor[key as PreReqType]?.screen === routeName,
  ) as PreReqType | undefined
}

/**
 * A method to set up the route history based on met pre-reqs and
 * push the next route if there are unmet pre-reqs.
 * @param navigation The SignupWorkflow or OfferApplicationWorkflow or ApplicationActivationWorkflow navigation object.
 * @param unmetPreReqs The pre-reqs that need to be fulfilled.
 * @param metPreReqs The pre-reqs that have been fulfilled.
 * @returns
 */
const SetupRouteHistory = ({
  navigation,
  unmetPreReqs,
  metPreReqs,
  selectedOffer,
}: {
  navigation: StackNavigationProp<WorkflowsStackParams>
  unmetPreReqs: PreReqType[]
  metPreReqs: PreReqType[]
  selectedOffer?: SelectedOfferInformation
}): void => {
  // if there are pre-reqs, then we need to get the route history
  const routeHistory = GetRouteHistoryFromPreReqs(metPreReqs)

  const routes = routeHistory.map((r) => ({name: r}))
  const nextRoute = GetNextRouteFromPreReqs(unmetPreReqs)
  if (nextRoute) {
    routes.push({name: nextRoute})
  }

  if (routes.length === 0) {
    throw new Error('No routes to set up')
  }

  wfLog(`Final routes: ${JSON.stringify(routes)}`)

  // if the before and after routes are the same, then we don't need to do anything
  // this is necessary because this function is going to be called whenever
  // the app foregrounds -- and sometimes we will be out of sync with where we should
  // be, but sometimes not.
  const {routes: existingRoutes, index: existingIndex} = navigation.getState()

  if (
    isEqual(
      routes.map((route) => route.name),
      existingRoutes.map((route) => route.name),
    ) &&
    existingIndex === routes.length - 1
  ) {
    wfDebug('Routes are already set up. Doing nothing.')
    return
  }

  // if the screen we are immediately presenting to the user needs to be
  // completed on the web, then we should redirect the user to the web
  const nextPreReq = GetNextPreReq(unmetPreReqs)
  if (nextPreReq && ShouldOfferPreReqBeFulfilledExclusivelyOnWeb(nextPreReq, selectedOffer)) {
    wfDebug('Next pre-req requires web. Redirecting to the web.')
    EmitRedirectionEvent('onboarding', true)
  }

  // if there are routes, then we need to reset the stack
  navigation.reset({
    index: routes.length - 1,
    routes,
  })
}

/**
 * A hook to manage the call to `SetupRouteHistory` and to set up the route history
 * @param navigation The SignupWorkflow or OfferApplicationWorkflow or ApplicationActivationWorkflow navigation object.
 * @param unmetPreReqs The pre-reqs that need to be fulfilled.
 * @param metPreReqs The pre-reqs that have been fulfilled.
 */
const useSetupRouteHistory = ({
  navigation,
  unmetPreReqs,
  metPreReqs,
  selectedOffer,
}: {
  navigation: StackNavigationProp<WorkflowsStackParams> | undefined
  unmetPreReqs: PreReqType[]
  metPreReqs: PreReqType[]
  selectedOffer?: SelectedOfferInformation
}): void => {
  const {debug, error} = useWFLogging('useSetupRouteHistory')
  debug('effect running')

  const [hasRunInitialSetup, setHasRunInitialSetup] = useState(false)

  const runSetup = useCallback(() => {
    if (!navigation) {
      wfDebug('No navigation object (yet?). Doing nothing.')
      return
    }

    try {
      wfLog('Setting up route history. Pre-reqs:', metPreReqs, '')
      SetupRouteHistory({navigation, unmetPreReqs, metPreReqs, selectedOffer})
    } catch (e) {
      if (e instanceof Error) {
        error(e, 'Error while setting up route history')
      }

      TrackAppEvent(
        AppEvents.Name.workflow_setup_route_history_failed,
        AppEvents.Category.Workflows,
        {
          unmetPreReqs,
          metPreReqs,
        },
      )

      const parent = navigation.getParent()
      parent?.reset({routes: [{name: 'ProductHub'}], index: 0})
    }
  }, [error, metPreReqs, navigation, selectedOffer, unmetPreReqs])

  useEffect(() => {
    if (hasRunInitialSetup || !navigation) {
      return
    }

    runSetup()
    setHasRunInitialSetup(true)
  }, [hasRunInitialSetup, navigation, runSetup])
}

const GetWorkflowNavigationOptions =
  <StackParams extends ParamListBase>() =>
  (props: {
    route: RouteProp<StackParams>
    navigation: StackNavigationProp<StackParams>
  }): StackNavigationOptions => {
    const {route, navigation} = props
    const headerLeftButtonType =
      (WorkflowHeaderButtonMap[route.name]?.left ?? navigation.canGoBack()) ? 'Back' : 'Logout'
    const headerRightButtonType = WorkflowHeaderButtonMap[route.name]?.right ?? 'None'

    return {
      title: '',
      headerLeft: HeaderButtonOptionFactory(
        headerLeftButtonType,
        // eslint-disable-next-line no-type-assertion/no-type-assertion
        navigation as StackNavigationProp<ParamListBase>,
      ),
      headerRight: HeaderButtonOptionFactory(
        headerRightButtonType,
        // eslint-disable-next-line no-type-assertion/no-type-assertion
        navigation as StackNavigationProp<ParamListBase>,
      ),
      gestureEnabled: headerLeftButtonType === 'Back',
    }
  }

const GetSignupWorkflowNavigationOptions = GetWorkflowNavigationOptions<SignupWorkflowStackParams>()
const GetOfferApplicationWorkflowNavigationOptions =
  GetWorkflowNavigationOptions<OfferApplicationWorkflowStackParams>()
const GetApplicationActivationWorkflowNavigationOptions =
  GetWorkflowNavigationOptions<ApplicationActivationWorkflowStackParams>()

export {
  GetApplicationActivationWorkflowNavigationOptions,
  GetCurrentRouteName,
  GetOfferApplicationWorkflowNavigationOptions,
  GetSignupWorkflowNavigationOptions,
  GetWorkflowNavigationOptions,
  MoveToNextPreReqRoute,
  OffersMinPreReqsNotMetSelector,
  SelectedOfferPreReqsNotMetSelectorFactory,
  SetupRouteHistory,
  useSetupRouteHistory,
}
