import {useEffect} from 'react'

import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {getShouldEnrollInCards} from 'src/lib/card/selectors'
import {clearShouldEnrollInCards} from 'src/lib/card/actions'
import {useInitializeCards} from 'src/products/loans/Landing/useInitializeCards'
import Log from 'src/lib/loggingUtil'
import {useCanAccessCards} from 'src/products/card/LoanDash/useCanAccessCards'

/**
 * If this user came from the cards deep link, enroll them in cards
 */
export const useEnrollInCardsIfNecessary = (): void => {
  const shouldEnrollInCards = usePfSelector(getShouldEnrollInCards)
  const {initializeCards, isLoading: isLoadingUseInitializeCards} = useInitializeCards()
  const {canAccessCards, isLoading: isLoadingCanAccessCards} = useCanAccessCards()
  const dispatch = usePfDispatch()

  useEffect(() => {
    const run = async (): Promise<void> => {
      if (!isLoadingUseInitializeCards && !isLoadingCanAccessCards && shouldEnrollInCards) {
        dispatch(clearShouldEnrollInCards())
        if (!canAccessCards) {
          Log.info('Adding user to card group')
          await initializeCards()
        }
      }
    }
    void run()
  }, [
    shouldEnrollInCards,
    dispatch,
    initializeCards,
    isLoadingUseInitializeCards,
    canAccessCards,
    isLoadingCanAccessCards,
  ])
}
