import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {Applications, EnvironmentType} from '../types'
import {GraphqlURIMap} from './ApiUris'
import {EnvState} from './types'

const initialState: EnvState = {
  environment: EnvironmentType.Dev,
  app: Applications.MOBILE,
  ...GraphqlURIMap[EnvironmentType.Dev],
}

export const EnvSlice = createSlice({
  name: 'Env',
  initialState,
  reducers: {
    UpdateEnvironment: (
      state,
      action: PayloadAction<Pick<EnvState, 'environment' | 'app' | 'token'>>,
    ) => {
      const {app = Applications.MOBILE, environment, token} = action.payload
      state.environment = environment
      state.app = app
      state.graphqlUri = GraphqlURIMap[app][environment].graphqlUri
      state.graphqlWSUri = GraphqlURIMap[app][environment].graphqlWSUri
      state.token = token
    },
  },
  extraReducers: (builder) => {
    builder.addCase('CLEAR_USER_ACTION', (state) => {
      state.token = ''
    })
  },
})

export const {UpdateEnvironment} = EnvSlice.actions

export default EnvSlice.reducer
