import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {
  AutopayModel,
  MinimumPaymentCalculationType,
  PaymentFrequency,
} from '@possible/cassandra/src/types/types.mobile.generated'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {RadioButtonList} from 'src/designSystem/components/molecules/RadioButtonList/RadioButtonList'
import {CardMinPaymentsQuery} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {getFrequencyText} from 'src/products/card/Autopay/useGetPaymentFrequency'
import {CardAutopaySchedulePaymentsMethod} from 'src/products/card/components/templates/CardPaymentScheduleTemplate/CardAutopaySchedulePaymentMethod'
import {
  PaymentSchedule,
  PaymentScheduleProps,
} from 'src/products/card/components/templates/CardPaymentScheduleTemplate/PaymentSchedule'

type CardPaymentScheduleTemplateProps = {
  buttonProps: ButtonLockupProps
  children?: React.ReactNode
  paymentFrequency: PaymentFrequency
  paymentSchedule: PaymentScheduleProps['schedule']
  subtitle?: string
  testID?: string
  title: string
  minPayData?: CardMinPaymentsQuery['me']['cardAccounts']['active']
  onOptionChange?: (id: string) => void
  isRoutedFromDashboard?: boolean
}

// eslint-disable-next-line complexity
const CardPaymentScheduleTemplate = ({
  buttonProps,
  children,
  paymentFrequency,
  paymentSchedule,
  subtitle,
  testID = 'CardPaymentScheduleTemplate',
  title,
  minPayData,
  onOptionChange,
  isRoutedFromDashboard,
}: CardPaymentScheduleTemplateProps): JSX.Element => {
  const {t} = useTranslation(['CardPaymentScheduleTemplate', 'Common'])
  const autopayModel: AutopayModel =
    minPayData?.status?.__typename === 'ActiveCardAccountStatus' ||
    minPayData?.status?.__typename === 'DeactivatedCardAccountStatus'
      ? minPayData?.status.autopayModel
      : AutopayModel.StatementBalance
  const [selectedOption, setSelectedOption] = useState<string>(autopayModel)

  useEffect(() => {
    handleOptionChange(autopayModel)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autopayModel])

  const isUserMinPayProgram = minPayData?.supportsMinPay

  const handleOptionChange = (autopayModel: string): void => {
    setSelectedOption(autopayModel)
    onOptionChange?.(autopayModel)
  }

  const minimumPaymentCalculation = minPayData?.cardAccountType?.minimumPaymentCalculation

  let minAmount: number | undefined
  if (minimumPaymentCalculation?.type === MinimumPaymentCalculationType.FixValue) {
    minAmount = minimumPaymentCalculation.value
  }

  return (
    <Page buttonProps={buttonProps} variant={'generic'} title={title} smallTopGap testID={testID}>
      <Box paddingBottom={'small'}>
        <PFText variant={isUserMinPayProgram ? 'p' : 'p_lg_semibold'}>{subtitle}</PFText>
      </Box>

      <Box gap={'small'} paddingBottom={'small'}>
        {isUserMinPayProgram ? (
          <Box gap={'small'} paddingBottom={'small'}>
            <RadioButtonList
              onPress={handleOptionChange}
              options={[
                {
                  id: 'STATEMENT_BALANCE',
                  text: t('StatementBalance'),
                  subText: t('StatementBalanceSubText'),
                  statusPillProps: {
                    text: t('Recommended'),
                  },
                  testID: 'Statement-Balance-Id',
                },
                {
                  id: 'MINIMUM_PAYMENT',
                  text: t('MinimumPayment'),
                  subText: minAmount
                    ? t('MinimumPaymentSubText', {minAmount})
                    : t('MinimumPaymentSubTextAlt'),
                  testID: 'Min-Payment-Id',
                },
              ]}
              selectedOption={selectedOption}
            />
          </Box>
        ) : (
          <Box>
            <PFText variant={'p'}>
              <Trans
                i18nKey={'Description'}
                t={t}
                values={{frequency: getFrequencyText(paymentFrequency, t)}}
                components={{
                  bold: (
                    <PFText
                      variant="p_semibold"
                      textProps={{style: {textTransform: 'lowercase'}}}
                    />
                  ),
                }}
              />
            </PFText>
          </Box>
        )}

        <CardAutopaySchedulePaymentsMethod
          title={isUserMinPayProgram ? t('PrimaryAccount') : t('PaymentMethodHeader')}
        />
        <Box>
          <PaymentSchedule
            disclaimer={
              isUserMinPayProgram
                ? ''
                : // Commenting out because no longer needed, yet keeping just in case we need this logic back
                  // (
                  //   !route.params?.isRoutedFromDashboard ? (
                  //     <Trans
                  //       i18nKey="MembershipDisclaimer"
                  //       t={t}
                  //       components={{bold: <PFText variant="p_semibold" color={NamedColors.SILVER} />}}
                  //       values={{
                  //         fee:
                  //           convertToDollarAmt(
                  //             // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                  //             minPayData?.cardAccountType?.monthlyMembershipFees,
                  //             false,
                  //           ) ?? '$16',
                  //       }}
                  //     />
                  //   ) : (
                  //     ''
                  //   )
                  // )
                  t('PaymentScheduleDisclaimer')
            }
            routedFromDashboard={isRoutedFromDashboard}
            header={isUserMinPayProgram ? t('AutoPaySchedule') : t('PaymentScheduleHeader')}
            schedule={paymentSchedule}
          />
        </Box>
      </Box>

      {children}
    </Page>
  )
}

export {CardPaymentScheduleTemplate}
export type {CardPaymentScheduleTemplateProps}
