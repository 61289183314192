import React, {useState} from 'react'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {get_formated_address, getPreferredAccountFormatted} from 'src/lib/user/utils'
import {LoanSubmissionTemplate} from 'src/products/loans/LoanApplicationSubmission/LoanSubmissionTemplate'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  LoanSubmissionDocument,
  PartnerStoreProductAttributionDocument,
} from 'src/products/loans/LoanApplicationSubmission/operations/LoanSubmission.gqls'
import {logOfferApplicationError} from 'src/products/general/OfferApplicationWorkflow/OfferApplication.utils'
import {openContactUsForm} from 'src/lib/contactUs'
import {
  TrySubmitReturnType,
  useLoanSubmission,
} from 'src/lib/loans/useLoanSubmission/useLoanSubmission'
import {LoanOriginator} from 'src/cassandra'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'

export type LoanApplicationSubmissionGQLContainerProps = {
  onSubmitLoanApplication: (loan: TrySubmitReturnType) => Promise<void>
  isLoading?: boolean
}

/**
 * Container to retrieve and save any data for the Loan Submission screen using GraphQL APIs.
 */
const LoanApplicationSubmissionGQLContainer: React.FC<
  LoanApplicationSubmissionGQLContainerProps
  // eslint-disable-next-line complexity
> = (props) => {
  const {t} = useTranslation(['LoanSubmission'])
  const navigation: StackNavigationProp<MainStackParamList> = useNavigation()
  const {onSubmitLoanApplication, isLoading} = props
  const [trySubmit] = useLoanSubmission()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const {
    selectedData: applicationData,
    loading: isLoadingApplicationData,
    error: applicationDataError,
  } = useCassandraQuery(
    LoanSubmissionDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (e) => {
        logOfferApplicationError(
          e,
          'LoanSubmission - LoanInitialApplicationSubmissionGQLContainer query failed', // the "LoanSubmission" text is required for a DataDog monitor
        )
      },
    },
    (data) => {
      return {
        ...data.me,
        currentPartnerOfferId: data.getCurrentOffer?.id,
        preferredAccount: data.me.bankAccounts.all?.find(
          (account) => account.preferredFundingSource,
        ),
      }
    },
  )

  const [applyPartnerOfferId] = useCassandraMutation(PartnerStoreProductAttributionDocument)

  const amount = applicationData?.onboarding?.loan?.amountSelected ?? ''
  const ssnLastFour = applicationData?.identification?.ssn?.mask ?? ''
  const fullName = `${applicationData?.profile?.name?.firstName} ${applicationData?.profile?.name?.lastName}`
  const email = applicationData?.profile?.email?.address ?? ''
  const birthDate = applicationData?.profile?.birthDate ?? ''
  const address = get_formated_address(applicationData?.profile?.home?.address)
  const isCoastalState =
    applicationData?.loans?.latestActionableLoan?.originator === LoanOriginator.Coastal
  const appliedCount = applicationData?.loans.countApplied ?? 0
  const currentOfferId = applicationData?.currentPartnerOfferId

  const handleOnLoanSubmission = async (): Promise<void> => {
    setIsSubmitting(true)
    const loan = await trySubmit(amount)
    if (appliedCount === 0 && currentOfferId && loan) {
      try {
        const result = await applyPartnerOfferId({
          variables: {offerId: currentOfferId, entityId: loan.id},
        })
        if (result.errors) {
          throw result.errors[0]
        }
      } catch (e) {
        // we want to log this error but not block the user from submitting the loan
        logOfferApplicationError(
          e,
          `LoanSubmission - Error while storing product attribution. offer_id: ${currentOfferId}, loan id: ${loan.id}`,
        )
      }
    }
    if (loan) {
      await onSubmitLoanApplication(loan)
    }
    setIsSubmitting(false)
  }

  const handleOnPressContactUs = (): void => {
    openContactUsForm(navigation)
  }

  return (
    <BaseTemplate
      pageTitle={t('ReviewAndSubmit')}
      isLoading={isLoadingApplicationData}
      error={applicationDataError}
    >
      <LoanSubmissionTemplate
        onSubmitLoanApplication={handleOnLoanSubmission}
        isSubmitBtnDisabled={isSubmitting || isLoading === true}
        isSubmitBtnLoading={isSubmitting}
        loanAmount={amount}
        ssnLastFour={ssnLastFour}
        fullName={fullName}
        address={address}
        birthDate={birthDate}
        email={email}
        bankAccount={getPreferredAccountFormatted(applicationData?.preferredAccount)}
        showCoastalFooterNote={isCoastalState}
        onPressContactUs={handleOnPressContactUs}
      />
    </BaseTemplate>
  )
}

export {LoanApplicationSubmissionGQLContainer}
