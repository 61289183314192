import React, {Component} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {StackScreenProps} from '@react-navigation/stack'

import {loans} from '@possible/generated/proto'
import {UserStateRefresh} from 'src/api/actions/user/userActions'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import AppEvents from 'src/lib/Analytics/app_events'
import {latestLoanSelector, latestLoanTypeSelector} from 'src/lib/loans/selector'
import {regionCodes} from 'src/lib/loans/consts'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import NavPageState from 'src/navigation/NavPageState'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {LoanDocumentType} from '@possible/cassandra/src/types/types.mobile.generated'
import {PageViewedAnalyticsProvider} from 'src/lib/Analytics/AnalyticsHelper'
import AcceptSelectAgreements from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAgreements/AcceptSelectAgreements'
import * as AgreementMixin from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAgreements/AcceptAgreements.utils'
import AcceptAchAndAgreements from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAchAndAgreements'
import {RegionSpecificProps} from 'src/products/loans/LoanApprovedActivation/AcceptAchAndAgreements/AcceptAchAndAgreements.types'
import {PfReduxState} from 'src/reducers/types'
import {ILoanRedux} from 'src/lib/loans/reducers/types'

export type Props = PropsFromRedux &
  RegionSpecificProps &
  StackScreenProps<MainStackParamList, 'AcceptAgreements'>

type State = {
  busy: boolean
}

class AcceptAgreements extends Component<Props, State> {
  stepName = LoanApprovedFlow.AcceptAgreements
  state = {busy: false}

  componentDidMount(): void {
    if (this.props.loan?.id) {
      void AgreementMixin.refreshEnv({
        loanId: this.props.loan?.id,
        setStateBusyTrue: () => {
          this.setState({
            busy: true,
          })
        },
        setStateBusyFalse: () => {
          this.setState({
            busy: false,
          })
        },
        userStateRefreshAction: this.props.UserStateRefresh,
      })
    }
  }

  onAccept = (): void => {
    TrackAppEvent(AppEvents.Name.accept_agreements_completed, AppEvents.Category.Checkout)

    if (this.props.route?.params?.onAccept) {
      this.props.route.params.onAccept()
    } else {
      const approval_step = NavPageState.GetNextApprovalState(this.stepName)
      void NavPageState.GoToScreen(this.props.navigation, approval_step)
    }
  }

  renderDocs(): JSX.Element {
    switch (this.props.loanType?.regionCode) {
      case regionCodes.Florida: {
        // it is expected that the types are the same for the 3 different documents that we list in the page
        const agreements = [
          {
            agreementName: AgreementMixin.ARBITRATION_AGREEMENT,
            title: 'Arbitration Agreement',
            type: LoanDocumentType.LoanAgreement,
            testID: 'FLORIDA_ARBITRATION_AGREEMENT',
          },
          {
            agreementName: AgreementMixin.STATUTORY_NOTICE,
            title: 'Florida Statutory Notice',
            type: LoanDocumentType.LoanAgreement,
            testID: 'FLORIDA_STATUTORY_NOTICE',
          },
          {
            agreementName: AgreementMixin.LOAN_AGREEMENT,
            title: 'Loan Agreement',
            type: LoanDocumentType.LoanAgreement,
            testID: 'FLORIDA_LOAN_AGREEMENT',
          },
        ]
        return (
          <AcceptSelectAgreements
            agreements={agreements}
            onAccept={this.onAccept}
            navigation={this.props.navigation}
          />
        )
      }

      case regionCodes.Texas: {
        const agreements = [
          {
            agreementName: AgreementMixin.CREDIT_SERVICES_DISCLOSURE_STATEMENT,
            title: 'Credit Services Disclosure Statement',
            type: LoanDocumentType.CreditServiceDisclosure,
            testID: 'TEXAS_CREDIT_SERVICES_DISCLOSURE_STATEMENT',
          },
          {
            agreementName: AgreementMixin.CREDIT_SERVICES_AGREEMENT,
            title: 'Credit Services Agreement',
            type: LoanDocumentType.CreditService,
            testID: 'TEXAS_CREDIT_SERVICES_AGREEMENT',
          },
          // it is expected that the types are the same for the 2 last documents
          {
            agreementName: AgreementMixin.ARBITRATION_AGREEMENT,
            title: 'Arbitration Agreement',
            type: LoanDocumentType.LoanAgreement,
            testID: 'TEXAS_ARBITRATION_AGREEMENT',
          },
          {
            agreementName: AgreementMixin.LOAN_AGREEMENT,
            title: 'Loan Agreement',
            type: LoanDocumentType.LoanAgreement,
            testID: 'TEXAS_LOAN_AGREEMENT',
          },
        ]
        return (
          <AcceptSelectAgreements
            agreements={agreements}
            onAccept={this.onAccept}
            navigation={this.props.navigation}
          />
        )
      }
      default:
        return (
          <AcceptAchAndAgreements
            onAccept={this.onAccept}
            navigation={this.props.navigation}
            title={this.props.title}
            description={this.props.description}
            renderRadioText={this.props.renderRadioText}
            nonAchDialog={this.props.nonAchDialog}
          />
        )
    }
  }

  render(): JSX.Element {
    return (
      <>
        <PageViewedAnalyticsProvider
          eventName={AppEvents.Name.accept_agreements_screen_viewed}
          eventCategory={AppEvents.Category.Checkout}
        />
        {this.renderDocs()}
      </>
    )
  }
}

const mapStateToProps = (
  state: PfReduxState,
): {
  loan: ILoanRedux | null
  loanType: loans.Loan.IType | undefined
} => {
  const loan = latestLoanSelector(state)

  const loanType = latestLoanTypeSelector(state)
  return {
    loan,
    loanType,
  }
}

const mapDispatchToProps = {
  UserStateRefresh,
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AcceptAgreements)
