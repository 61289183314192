import {Platform} from 'react-native'
import {head, uniq} from 'lodash'

import {Consumer} from '@possible/cassandra'

import {RoutesToRemoveFromHistory, WorkflowDescriptor} from 'src/workflows/constants'
import {wfDebug} from 'src/workflows/logging'
import {BaselinePreReqSortFunction} from 'src/workflows/order/baselinePreReqOrder'
import {PreReqSortFunctionType} from 'src/workflows/order/types'
import {
  PreReqType,
  ProductsQueryApplicationsType,
  ProductsQueryEligibleOffersType,
  ProductsQueryOfferType,
  SelectedOfferInformation,
  WorkflowsStackParams,
} from 'src/workflows/types'

import {getIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'

let preReqSortFunction: PreReqSortFunctionType = BaselinePreReqSortFunction
export const GetPreReqSortFunction = (): PreReqSortFunctionType => preReqSortFunction
export const SetPreReqSortFunction = (sortFunction: PreReqSortFunctionType): void => {
  preReqSortFunction = sortFunction
}

const getPreReqsForOfferId = (
  offerId: string,
  offers: ProductsQueryEligibleOffersType,
  type: 'requirementMet' | 'requirementNotMet',
): PreReqType[] | undefined => {
  for (const pOffer of offers.all) {
    for (const offer of pOffer.offers) {
      if (offer.id === offerId) {
        return offer.preReqs[type]
      }
    }
  }

  return undefined
}

export const GetOfferForId = (
  offerId: string,
  offers: ProductsQueryEligibleOffersType,
): ProductsQueryOfferType | null => {
  const {all} = offers
  for (const pOffer of all) {
    for (const offer of pOffer.offers) {
      if (offer.id === offerId) {
        return offer
      }
    }
  }

  return null
}

/**
 * Retrieve all met pre-reqs for a given offer.
 * @param offerId The offer id to search for.
 * @param offers The `ProductsOffersCollection` in which to search.
 * @returns The offer's `requirementMet` pre-reqs if found else []
 */
export const GetMetPreReqsForOfferId = (
  offerId: string,
  offers: ProductsQueryEligibleOffersType,
): PreReqType[] | undefined => getPreReqsForOfferId(offerId, offers, 'requirementMet')

/**
 * Retrieve all unmet pre-reqs for a given offer.
 * @param offerId The offer id to search for.
 * @param offers The `ProductsOffersCollection` in which to search.
 * @returns The offer's `requirementNotMet` pre-reqs if found else []
 */
export const GetUnmetPreReqsForOfferId = (
  offerId: string,
  offers: ProductsQueryEligibleOffersType,
): PreReqType[] | undefined => getPreReqsForOfferId(offerId, offers, 'requirementNotMet')

/**
 * Sort an array of pre-reqs by a sort function.
 * @param preReqs An array of ProductPreRequisiteType to sort
 * @param sortFunction The sorting function to use. Defaults to `baselinePreReqSortFunction`.
 * @returns The `preReqs` passed in sorted by the sort function.
 */
export const GetSortedPreReqs = (preReqs: PreReqType[]): PreReqType[] => {
  const sortFunction: PreReqSortFunctionType = GetPreReqSortFunction()
  return [...preReqs].sort(sortFunction)
}

/**
 * Takes a pre-req and maps it to a route that fulfills it.
 * @param preReq The pre-req to map.
 * @returns A route that fulfills that pre-req.
 */
export const MapPreReqToRoute = (preReq?: PreReqType): keyof WorkflowsStackParams | undefined => {
  if (!preReq) {
    return undefined
  }

  return WorkflowDescriptor[preReq]?.screen
}

/**
 * Given that a single route can fulfill multiple pre-reqs,
 * we should dedupe routes from a route array built from pre-reqs.
 * @param routes
 * @returns A deduped version of `routes`.
 */
export const DedupeRoutes = (
  routes: (keyof WorkflowsStackParams)[],
): (keyof WorkflowsStackParams)[] => {
  const uniques: (keyof WorkflowsStackParams)[] = uniq(routes)
  return uniques.filter((u) => !!u)
}

/**
 * Map an array of pre-reqs to an array of routes. Then de-dupe them.
 * @param preReqs The pre-reqs to map.
 * @returns A deduped array of routes mapped from pre-reqs.
 */
export const PreRequisitesToRoutes = (preReqs: PreReqType[]): (keyof WorkflowsStackParams)[] => {
  const routes: (keyof WorkflowsStackParams)[] = []
  for (const req of preReqs) {
    const route = MapPreReqToRoute(req)
    if (route) {
      routes.push(route)
    }
  }
  return DedupeRoutes(routes)
}

/**
 * Retrieve the route history from the `requirementsMet` field of
 * a `ProductPreRequisiteCollection`
 * @param preReqsMet The backend `ProductPreRequisiteCollection.requirementsMet`
 * for an offer or user minimum pre-reqs.
 * @returns An array of routes comprising the "history" of pre-requisite fulfillment.
 */
export const GetRouteHistoryFromPreReqs = (
  preReqsMet: PreReqType[],
): (keyof WorkflowsStackParams)[] => {
  const sortedPreReqs = GetSortedPreReqs(preReqsMet)
  const routes = PreRequisitesToRoutes(sortedPreReqs)
  wfDebug(`routes in history = ${JSON.stringify(routes)}`)
  return routes.filter((r) => !RoutesToRemoveFromHistory.includes(r))
}

/**
 * Retrieve the next pre-req to fulfill based on unmet requirements.
 * If a current pre-req and met pre-reqs are provided, the result returned
 * will factor in current position relative to met.
 * @param unmetPreReqs Backend and frontend requirements not yet met.
 * @param currentPreReq The current pre-req being fulfilled.
 * @param metPreReqs The pre-reqs that have been fulfilled.
 * @returns The next pre-req to fulfill or undefined if there are no more.
 */
export const GetNextPreReq = (
  unmetPreReqs: PreReqType[],
  currentPreReq?: PreReqType,
  metPreReqs?: PreReqType[],
): PreReqType | undefined => {
  if (unmetPreReqs.length === 0) {
    return undefined
  }

  if (currentPreReq !== undefined) {
    if (unmetPreReqs.includes(currentPreReq)) {
      return currentPreReq
    }

    if (metPreReqs?.includes(currentPreReq)) {
      const sortedMet = GetSortedPreReqs(metPreReqs)
      const index = sortedMet.indexOf(currentPreReq)
      if (index !== sortedMet.length - 1) {
        return sortedMet[index + 1]
      }
    }
  }

  return head(GetSortedPreReqs(unmetPreReqs))
}

/**
 * Retrieve the next route of which to navigate based on unmet requirements.
 * @param preReqsNotMet The backend `ProductPreRequisiteCollection.requirementsNotMet`
 * for an offer or user minimum pre-reqs.
 * @returns A route representing a single pre-requisite fulfillment.
 */
export const GetNextRouteFromPreReqs = (
  preReqsNotMet: PreReqType[],
): keyof WorkflowsStackParams | undefined => {
  const nextPreReq = GetNextPreReq(preReqsNotMet)
  wfDebug(`nextPreReq = ${nextPreReq}`)
  if (!nextPreReq) {
    return undefined
  }

  const route = MapPreReqToRoute(nextPreReq)
  wfDebug(`next route = ${route}`)

  return route
}

/**
 * Use to determine if a given pre-req for a given offer should be
 * fulfilled exclusively on web.
 * @param nextPreReq The pre-req in question
 * @param selectedOffer The offer with which that pre-req is associated
 * @returns True if the pre-req should be fulfilled on web, false otherwise.
 */
export const ShouldOfferPreReqBeFulfilledExclusivelyOnWeb = (
  nextPreReq: PreReqType,
  selectedOffer?: SelectedOfferInformation,
): boolean => {
  const isRedirectEnabled = getIsFeatureFlagEnabled('loan-application-handoff-to-web-on-android')
  const offerTypename = selectedOffer?.offer.__typename
  const redirectWeb = WorkflowDescriptor[nextPreReq]?.redirectToWeb
  if (isRedirectEnabled && redirectWeb && offerTypename) {
    return (
      redirectWeb.platforms?.includes(Platform.OS) &&
      redirectWeb.associatedOfferTypenames.includes(offerTypename)
    )
  }

  return false
}

/**
 * Does user have access to offer
 */
export const canAccessOffer = (
  offer: Consumer.types.CardAccountOffer | Consumer.types.LoanOffer,
  isCardsUser: boolean,
): boolean =>
  offer.__typename === 'LoanOffer' || (offer.__typename === 'CardAccountOffer' && isCardsUser)

export const isInActivationStage = (applications: ProductsQueryApplicationsType[]): boolean => {
  return (applications ?? []).some((application) => {
    const statusTypeName = application.product.status.__typename ?? ''
    return statusTypeName === 'ApprovedCardAccountStatus' || statusTypeName === 'ApprovedLoanStatus'
  })
}
