import React, {FC} from 'react'
import {StackScreenProps} from '@react-navigation/stack'
import {useNavigation} from '@react-navigation/native'

import {PaymentReviewTilaAndLoanAgreementCARESTContainer} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCARESTContainer'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import * as LoanApprovedFlow from 'src/navigation/LoanApprovedFlow'
import NavPageState from 'src/navigation/NavPageState'
import {CALoanFinalActivationNavigationScreenAnalytics} from 'src/products/loans/LoanApprovedActivation/PaymentReviewTilaAndLoanAgreementCA/PaymentReviewTilaAndLoanAgreementCA.utils'

type PaymentReviewTilaAndLoanAgreementCANavPageStateContainerProps = Pick<
  StackScreenProps<MainStackParamList, 'PaymentReviewTilaAndLoanAgreementCA'>,
  'navigation'
>

const PaymentReviewTilaAndLoanAgreementCANavPageStateContainer: FC = () => {
  const navigation: PaymentReviewTilaAndLoanAgreementCANavPageStateContainerProps = useNavigation()

  CALoanFinalActivationNavigationScreenAnalytics('NavPageState')

  const handleOnContinue = async (): Promise<void> => {
    const nextPage = NavPageState.GetNextApprovalState(
      LoanApprovedFlow.PaymentReviewTilaAndLoanAgreementCA,
    )
    await NavPageState.GoToScreen(navigation, nextPage)
  }
  // When we delete nav page state please delete the logic around isWorkflows in PaymentReviewTilaAndLoanAgreementCARESTContainer
  return (
    <PaymentReviewTilaAndLoanAgreementCARESTContainer
      onContinue={handleOnContinue}
      isWorkflows={false}
    />
  )
}

export {PaymentReviewTilaAndLoanAgreementCANavPageStateContainer}
